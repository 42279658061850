import {CardContent, Chip} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {Filter, FilterTimeFrame, TimeframePicker, TimeFrameType} from "@variocube/app-ui";
import React from "react";
import {useLocalization} from "../../../i18n";
import {useFormattedDates} from "../../../utils/date";

export type UtilizationFilter = {
	timeFrame: FilterTimeFrame;
};

interface UtilizationFilterProps {
	filter: UtilizationFilter;
	onChange: (value: UtilizationFilter) => any;
}

export function UtilizationFilter({filter, onChange}: Readonly<UtilizationFilterProps>) {
	const {t, s} = useLocalization();
	const formattedDates = useFormattedDates(filter.timeFrame.from, filter.timeFrame.until);

	return (
		<Filter
			label={t("filter.title")}
			active={[
				filter.timeFrame && filter.timeFrame.type != "All" && (
					<Chip
						key={"timeframe"}
						label={formattedDates}
						onDelete={() => onChange({...filter, timeFrame: {type: TimeFrameType.All}})}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TimeframePicker
							value={filter.timeFrame}
							onChange={(timeFrame) => onChange({...filter, timeFrame})}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Filter>
	);
}
