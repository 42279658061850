import {Alert, AlertTitle} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, ConfirmButton, EditForm} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {Feature} from "../../api";
import {usePlanApi} from "../../api/plan";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {useNumericParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {PlanForm} from "./PlanForm";

export function PlanEdit() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const planId = useNumericParam("planId");
	const {getPlan, updatePlan, deletePlan} = usePlanApi();
	const tenantId = useTenantId();

	const [name, setName] = useState<string>("");
	const [selectedFeatures, setSelectedFeatures] = useState<Feature[]>([]);

	const {loading, result: plan} = useAsync(getPlan, [planId]);

	useEffect(() => {
		setName(plan?.name ?? "");
		setSelectedFeatures(plan?.features ?? []);
	}, [plan]);

	const handleSave = async () => {
		if (!plan) return;
		await updatePlan(plan.id, {
			...plan,
			name,
			features: selectedFeatures,
		});
		navigate(`../${plan.id}`);
	};

	const handleDelete = async () => {
		await deletePlan(planId);
		navigate(`/${tenantId}/plans`);
	};

	if (!plan) return null;

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."}>
					{t("plans.title")}
				</BreadcrumbLink>
				<BreadcrumbItem>{t("plans.edit.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("plans.edit.title")} />
			<EditForm onSave={handleSave} labels={t} loading={loading}>
				<PlanForm
					name={name}
					setName={setName}
					selectedFeatures={selectedFeatures}
					setSelectedFeatures={setSelectedFeatures}
					labelTexts={{
						nameLabel: t("plans.edit.name"),
						featuresLabel: t("plans.edit.features"),
					}}
				/>
			</EditForm>
			<Alert
				severity="error"
				action={
					<ConfirmButton
						cancel={t("cancel")}
						title={t("plans.delete.title")}
						onConfirm={handleDelete}
						color="error"
					>
						{t("plans.delete.confirm")}
					</ConfirmButton>
				}
			>
				<AlertTitle>
					{t("plans.delete.title")}
				</AlertTitle>
				{t("plans.delete.message")}
			</Alert>
		</View>
	);
}
