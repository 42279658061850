import React from "react";
import {Route, Routes} from "react-router-dom";
import {PlanCreate} from "./PlanCreate";
import {PlanEdit} from "./PlanEdit";
import {PlanList} from "./PlanList";
import {PlanView} from "./PlanView";

export function Plans() {
	return (
		<Routes>
			<Route index element={<PlanList />} />
			<Route path="new" element={<PlanCreate />} />
			<Route path=":planId" element={<PlanView />} />
			<Route path=":planId/edit" element={<PlanEdit />} />
		</Routes>
	);
}
