import React from "react";
import {Route, Routes} from "react-router-dom";
import StatisticsView from "./StatisticsView";

export function Statistics() {
	return (
		<Routes>
			<Route index element={<StatisticsView />} />
		</Routes>
	);
}
