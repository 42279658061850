import {CardContent, CardHeader, FormHelperText, Grid} from "@mui/material";
import {EditForm, TextField} from "@variocube/app-ui";
import React, {ReactNode, useEffect, useState} from "react";
import {SupportSettings} from "../../api";
import {useLocalization} from "../../i18n";

interface SupportSettingsEditFormProps {
	loading: boolean;
	supportSettings?: SupportSettings;
	onSave: (supportSettings: SupportSettings) => Promise<any>;
	title?: ReactNode;
}

export function SupportSettingsEditForm(props: SupportSettingsEditFormProps) {
	const {t} = useLocalization();
	const {loading, supportSettings, onSave, title} = props;

	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [info, setInfo] = useState("");

	useEffect(() => {
		if (supportSettings) {
			setName(supportSettings.name ?? "");
			setPhone(supportSettings.phone ?? "");
			setEmail(supportSettings.email ?? "");
			setInfo(supportSettings.info ?? "");
		}
	}, [supportSettings]);

	async function handleSave() {
		await onSave({
			name,
			phone,
			email,
			info,
		});
	}

	return (
		<EditForm loading={loading} labels={t} onSave={handleSave}>
			{title && <CardHeader title={title} />}
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							label={t("settings.support.name.title")}
							value={name}
							onChange={setName}
							fullWidth
						/>
						<FormHelperText>{t("settings.support.name.helperText")}</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("settings.support.phone.title")}
							value={phone}
							onChange={setPhone}
							fullWidth
						/>
						<FormHelperText>{t("settings.support.phone.helperText")}</FormHelperText>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							label={t("settings.support.email.title")}
							value={email}
							onChange={setEmail}
							fullWidth
						/>
						<FormHelperText>{t("settings.support.email.helperText")}</FormHelperText>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label={t("settings.support.info.title")}
							value={info}
							onChange={setInfo}
							multiline
							rows={5}
							fullWidth
						/>
						<FormHelperText>{t("settings.support.info.helperText")}</FormHelperText>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}
