import {
	AuditTimeline as VcAppUiAuditTimeline,
	AuditTimelineItem,
	AuditTimelineItemSkeleton,
	defined,
	ErrorAlert,
	SpringPageable,
	SwitchableTemporalFormat,
	tryParseInstant,
} from "@variocube/app-ui";
import React, {useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {AuditLogEntry, PageAuditLogEntry} from "../../api";
import {useLocalization} from "../../i18n";
import {AuditLogEntryDetailsButton} from "./AuditLogEntryDetailsButton";

interface AuditTimelineProps<P> {
	load: (param: P, pageable: SpringPageable) => Promise<PageAuditLogEntry>;
	param: P;
	reloadRef?: (reload: () => Promise<void>) => any;
}

export function AuditTimeline<P>({load, param, reloadRef}: AuditTimelineProps<P>) {
	const [page, setPage] = useState(0);
	const [reload, setReload] = useState(0);

	const pageable = useMemo(() => ({
		page,
	}), [page]);

	const {loading, error, result, execute} = useAsync(
		(param: P, pageable: SpringPageable, _: number) => load(param, pageable),
		[param, pageable, reload],
	);

	const [entries, setEntries] = useState<AuditLogEntry[]>([]);

	useEffect(() => {
		if (reloadRef) {
			reloadRef(async () => {
				setEntries([]);
				setPage(0);
				setReload(prev => prev + 1);
			});
		}
	}, [reloadRef, param, execute]);

	useEffect(() => {
		if (result) {
			const {content, last} = result;
			if (defined(content)) {
				setEntries(prev => [...prev, ...content]);
			}
			if (!last) {
				setPage(page => page + 1);
			}
		}
	}, [result]);

	return (
		<VcAppUiAuditTimeline>
			{entries.map(entry => <Item key={entry.id} entry={entry} />)}
			{error && <ErrorAlert error={error} />}
			{loading && <AuditTimelineItemSkeleton />}
		</VcAppUiAuditTimeline>
	);
}

function Item({entry}: { entry: AuditLogEntry }) {
	const {t} = useLocalization();
	const eventTimestamp = tryParseInstant(entry.eventTimestamp);
	return (
		<AuditTimelineItem
			created={<SwitchableTemporalFormat value={eventTimestamp} />}
			actor={entry.actor}
			message={t(`audit.actions.${entry.action}`)}
			changes={<AuditLogEntryDetailsButton entry={entry} />}
			connector
		/>
	);
}
