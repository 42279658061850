import {Button} from "@mui/material";
import {
	DataTable,
	DataTableCellProps,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
	useStorage,
} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {Plan} from "../../api";
import {usePlanApi} from "../../api/plan";
import {EmptyList} from "../../controls";
import {TFunc, useLocalization} from "../../i18n";
import {GroupFilter} from "../group/GroupFilter";
import {View, ViewHeader} from "../View";
import {PlanLinkChip} from "./PlanChip";
import {PlanFilter} from "./PlanFilter";

export function PlanList() {
	const {t} = useLocalization();

	const {onPageChange, onSort, ...storage} = useDataTableStorage("PlanList.paging");

	const [filter, setFilter] = useStorage<GroupFilter>("PlanList.filter", {});
	const pageable = useSpringPageable(storage);

	const query = useMemo(() => ({
		...filter,
		...pageable,
	}), [filter, pageable]);

	const {queryPlans} = usePlanApi();
	const {loading, error, result} = useAsync(queryPlans, [query]);

	const {rows, page} = useSpringPage(result);
	const columns = useMemo(() => createColumns(t), [t]);

	return (
		<View>
			<ViewHeader
				title={t("plans.title")}
				actions={
					<Button variant="contained" color="primary" component={RouterLink} to="new">
						{t("plans.create.title")}
					</Button>
				}
			/>
			<PlanFilter value={filter} onSearch={search => setFilter({...filter, search})} />
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				onSort={onSort}
				onPageChange={onPageChange}
				empty={<EmptyList />}
				{...storage}
			/>
		</View>
	);
}

function createColumns(t: TFunc) {
	return [
		{
			label: t("plans.name"),
			field: "name",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<Plan>) => <PlanLinkChip plan={row} />,
		},
	];
}
