import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {CloseIcon, CodeBlock, Json, SwitchableTemporalFormat, tryParseInstant, useFlag} from "@variocube/app-ui";
import React, {Fragment} from "react";
import {useAsync} from "react-async-hook";
import {
	AuditLogEntry,
	useBookingApi,
	useCompartmentApi,
	useGroupApi,
	useLocationApi,
	useTenantUserApi,
} from "../../api";
import {DisplayField} from "../../controls/DisplayField";
import {useLocalization} from "../../i18n";
import {BookingLinkChip} from "../booking/BookingChip";
import {CompartmentLinkIconButton} from "../compartment/CompartmentLinkIconButton";
import {LockChip} from "../compartment/LockChip";
import {GroupLinkChip} from "../group/GroupChip";
import {LocationLinkChip} from "../location/LocationChip";
import {UserLinkChip} from "../user/UserChip";

export function AuditLogEntryDetailsButton({entry}: Readonly<{ entry: AuditLogEntry }>) {
	const {t} = useLocalization();
	const [open, setOpen, clearOpen] = useFlag(false);

	return (
		<Fragment>
			<Button variant="text" color="inherit" onClick={setOpen}>
				{t("audit.showDetails")}
			</Button>
			{open && <DetailDialog onClose={clearOpen} entry={entry} />}
		</Fragment>
	);
}

interface DetailDialogProps {
	onClose: () => void;
	entry: AuditLogEntry;
}

function DetailDialog({entry, onClose}: Readonly<DetailDialogProps>) {
	const {t} = useLocalization();
	const {getLocation} = useLocationApi();
	const {getCompartment} = useCompartmentApi();
	const {getBooking} = useBookingApi();
	const {getUser} = useTenantUserApi();
	const {getGroup} = useGroupApi();

	const {
		id,
		created,
		eventTimestamp,
		patch,
		data,
		locationId,
		compartmentId,
		username,
		groupId,
		bookingId,
		lockId,
		action,
		actor,
	} = entry;

	const json = patch || data;

	const location = useOptionalAsync(getLocation, locationId);
	const compartment = useOptionalAsync(getCompartment, compartmentId);
	const user = useOptionalAsync(getUser, username);
	const booking = useOptionalAsync(getBooking, bookingId);
	const group = useOptionalAsync(getGroup, groupId);

	return (
		<Dialog open onClose={onClose} maxWidth={"lg"} fullWidth>
			<DialogTitle>
				{t(`audit.actions.${action}`)}
			</DialogTitle>
			<Box position="absolute" top="2px" right="2px">
				<IconButton onClick={onClose} size="large">
					<CloseIcon />
				</IconButton>
			</Box>
			<DialogContent>
				<Grid container spacing={4}>
					<Grid item>
						<DisplayField label={t("audit.id")}>
							{id}
						</DisplayField>
					</Grid>
					<Grid item>
						<DisplayField label={t("audit.created")}>
							<SwitchableTemporalFormat value={tryParseInstant(created)} />
						</DisplayField>
					</Grid>
					<Grid item>
						<DisplayField label={t("audit.eventTimestamp")}>
							<SwitchableTemporalFormat value={tryParseInstant(eventTimestamp)} />
						</DisplayField>
					</Grid>
					<Grid item>
						<DisplayField label={t("audit.actor")}>
							<UserLinkChip user={entry.user} fallback={actor} />
						</DisplayField>
					</Grid>

					{locationId && (
						<Grid item>
							<DisplayField label={t("locations.single")}>
								{(location.loading || location.error) && locationId}
								{location.result && <LocationLinkChip location={location.result} />}
							</DisplayField>
						</Grid>
					)}
					{compartmentId && (
						<Grid item>
							<DisplayField label={t("compartments.single")}>
								{(compartment.loading || compartment.error) && compartmentId}
								{compartment.result && <CompartmentLinkIconButton compartment={compartment.result} />}
							</DisplayField>
						</Grid>
					)}
					{groupId && (
						<Grid item>
							<DisplayField label={t("groups.single")}>
								{(group.loading || group.error) && groupId}
								{group.result && <GroupLinkChip group={group.result} />}
							</DisplayField>
						</Grid>
					)}
					{username && (
						<Grid item>
							<DisplayField label={t("users.single")}>
								{(user.loading || user.error) && username}
								{user.result && <UserLinkChip user={user.result.user} />}
							</DisplayField>
						</Grid>
					)}
					{bookingId && (
						<Grid item>
							<DisplayField label={t("bookings.single")}>
								{(booking.loading || booking.error) && bookingId}
								{booking.result && <BookingLinkChip booking={booking.result} />}
							</DisplayField>
						</Grid>
					)}
					{lockId && (
						<Grid item>
							<DisplayField label={t("locks.single")}>
								<LockChip lock={lockId} />
							</DisplayField>
						</Grid>
					)}

					{json && (
						<Grid item xs={12}>
							<Typography variant="body2">
								<CodeBlock>
									<Json data={json} />
								</CodeBlock>
							</Typography>
						</Grid>
					)}
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

function useOptionalAsync<T, R>(loader: (id: T) => Promise<R>, id: T | undefined) {
	return useAsync(async (id: T | undefined) => {
		if (id) {
			return await loader(id);
		}
	}, [id]);
}
