import {useMemo} from "react";
import {useAccessToken} from "../auth";
import {createApiClient} from "./client";

export function useLockScanApi() {
	const accessToken = useAccessToken();
	return useMemo(() => createLockScanApi(accessToken), [accessToken]);
}

function createLockScanApi(accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		scanLock: (lockId: string) =>
			api.get("/api/locks/{lockId}", {
				path: {lockId},
			}),
	};
}
