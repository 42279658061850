import {Tabs} from "@variocube/app-ui";
import React from "react";
import {useLocalization} from "../../i18n";
import {useSearchParam} from "../../utils/search";
import {View, ViewHeader} from "../View";
import {AuditSettings} from "./AuditSettings";
import {TenantBookingSettings} from "./TenantBookingSettings";
import {TenantCompartmentSettings} from "./TenantCompartmentSettings";
import {TenantSupportSettings} from "./TenantSupportSettings";

export function Settings() {
	const {t} = useLocalization();
	const [tab, setTab] = useSearchParam("tab", "booking");
	return (
		<View>
			<ViewHeader
				title={t("settings.title")}
			/>
			<Tabs
				value={tab}
				onChange={(_, tab) => setTab(tab)}
				items={[
					{label: t("bookings.title"), value: "booking"},
					{label: t("audit.title"), value: "audit"},
					{label: t("compartments.title"), value: "compartment"},
					{label: t("settings.support.title"), value: "support"},
				]}
			/>
			{tab == "booking" && <TenantBookingSettings />}
			{tab == "audit" && <AuditSettings />}
			{tab == "compartment" && <TenantCompartmentSettings />}
			{tab == "support" && <TenantSupportSettings />}
		</View>
	);
}
