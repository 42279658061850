import {CardContent, CardHeader, Chip} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {
	Filter,
	Now,
	PlainDateTime,
	PlainDateTimePicker,
	RadioGroup,
	SpringPageable,
	tryParseInstant,
	useTemporalFormat,
} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {BookingQuery, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationChip} from "../location/LocationChip";
import {LocationSelect} from "../location/LocationSelect";

export type BookingFilter = Omit<BookingQuery, keyof SpringPageable>;

interface BookingFilterProps {
	value: BookingFilter;
	onChange: (value: BookingFilter) => any;
}

export const UserTimeZoneId = Now.zonedDateTimeISO().timeZone;

export function BookingFilter({value, onChange}: BookingFilterProps) {
	const {t, s} = useLocalization();

	const {getLocation} = useLocationApi();

	const location = useAsync(async (locationId?: number) => {
		if (locationId) {
			return await getLocation(locationId);
		}
	}, [value.location]);

	const from = tryParseInstant(value.from)?.toZonedDateTimeISO(UserTimeZoneId).toPlainDateTime();
	const until = tryParseInstant(value.until)?.toZonedDateTimeISO(UserTimeZoneId).toPlainDateTime();

	const fromFormatted = useTemporalFormat({value: from});
	const untilFormatted = useTemporalFormat({value: until});

	function handleFromChange(from: PlainDateTime | null) {
		onChange({...value, from: from?.toZonedDateTime(UserTimeZoneId).toInstant().toString()});
	}

	function handleUntilChange(from: PlainDateTime | null) {
		onChange({...value, until: from?.toZonedDateTime(UserTimeZoneId).toInstant().toString()});
	}

	return (
		<Filter
			label={t("filter.title")}
			enableSearch
			onSearch={search => onChange({...value, search})}
			active={[
				value.status && (
					<Chip
						key={"status"}
						label={t(`bookings.status.${value.status}`)}
						onDelete={() => onChange({...value, status: undefined})}
					/>
				),
				from && (
					<Chip
						key={"from"}
						label={`${t("bookings.from")}: ${fromFormatted}`}
						onDelete={() => onChange({...value, from: undefined})}
					/>
				),
				until && (
					<Chip
						key={"until"}
						label={`${t("bookings.until")}: ${untilFormatted}`}
						onDelete={() => onChange({...value, until: undefined})}
					/>
				),
				location.result && (
					<LocationChip
						key={"location"}
						location={location.result}
						onDelete={() => onChange({...value, location: undefined})}
					/>
				),
				value.search && (
					<Chip
						key={"search"}
						label={`${t("filter.search")}: ${value.search}`}
						onDelete={() => onChange({...value, search: undefined})}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<CardHeader title={t("bookings.timeframe")} />
						<CardContent>
							<Grid container spacing={2}>
								<Grid item>
									<PlainDateTimePicker
										label={t("bookings.from")}
										value={from ?? null}
										onChange={handleFromChange}
									/>
								</Grid>
								<Grid item>
									<PlainDateTimePicker
										label={t("bookings.until")}
										value={until ?? null}
										onChange={handleUntilChange}
									/>
								</Grid>
							</Grid>
						</CardContent>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<CardHeader title={t("bookings.status.title")} />
						<CardContent>
							<RadioGroup
								value={value.status ?? "all"}
								onChange={status => onChange({...value, status: status == "all" ? undefined : status})}
								options={["all", "ACTIVE", "ENDED", "FUTURE"]}
								renderLabel={s("bookings.status")}
							/>
						</CardContent>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<CardHeader title={t("locations.single")} />
						<CardContent>
							<LocationSelect
								value={location.result ?? null}
								onChange={location => onChange({...value, location: location?.id})}
							/>
						</CardContent>
					</Grid>
				</Grid>
			</CardContent>
		</Filter>
	);
}
