import {Button} from "@mui/material";
import {useDataTableStorage, useSpringPage, useSpringPageable, useStorage} from "@variocube/app-ui";
import React, {Fragment, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link as RouterLink} from "react-router-dom";
import {useBookingApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useAnyPermission} from "../../tenant";
import {View, ViewHeader} from "../View";
import {BookingFilter} from "./BookingFilter";
import {BookingRetrievalConfirmation} from "./BookingRetrievalConfirmation";
import BookingTable from "./BookingTable";

export function BookingList() {
	const {t} = useLocalization();

	const canCreate = useAnyPermission("BOOKING_CREATE", "BOOKING_CREATE_OWN");

	return (
		<View>
			<ViewHeader
				title={t("bookings.title")}
				actions={canCreate && (
					<Button variant="contained" color="primary" component={RouterLink} to="new">
						{t("bookings.create.title")}
					</Button>
				)}
			/>
			<BookingRetrievalConfirmation>
				<BookingListConfirmed />
			</BookingRetrievalConfirmation>
		</View>
	);
}

function BookingListConfirmed() {
	const {queryBookings} = useBookingApi();

	const {onPageChange, onSort, ...storage} = useDataTableStorage("BookingList.paging");
	const pageable = useSpringPageable(storage);

	const [filter, setFilter] = useStorage<BookingFilter>("BookingList.filter", {status: "ACTIVE"});

	const query = useMemo(() => ({
		...filter,
		...pageable,
	}), [filter, pageable]);

	const {loading, error, result} = useAsync(queryBookings, [query]);
	const {rows, page} = useSpringPage(result);

	return (
		<Fragment>
			<BookingFilter value={filter} onChange={setFilter} />
			<BookingTable
				loading={loading}
				error={error}
				rows={rows}
				page={page}
				onSort={onSort}
				onPageChange={onPageChange}
				storage={storage}
			/>
		</Fragment>
	);
}
