import {Button} from "@mui/material";
import {useDataTableStorage, useSpringPage, useSpringPageable, useStorage} from "@variocube/app-ui";
import React, {useEffect, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link, useSearchParams} from "react-router-dom";
import {useCompartmentApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useAnyPermission} from "../../tenant";
import {View, ViewHeader} from "../View";
import {CompartmentFilter} from "./CompartmentFilter";
import CompartmentTable from "./CompartmentTable";

export function CompartmentList() {
	const {t} = useLocalization();

	const {queryCompartments} = useCompartmentApi();

	const canCreate = useAnyPermission("COMPARTMENT_CREATE");

	const {onPageChange, onSort, ...storage} = useDataTableStorage("CompartmentList.paging");
	const pageable = useSpringPageable(storage);

	const [filter, setFilter] = useStorage<CompartmentFilter>("CompartmentList.filter", {hasLock: true});

	const query = useMemo(() => ({
		...filter,
		...pageable,
	}), [filter, pageable]);

	const {loading, error, result} = useAsync(queryCompartments, [query]);
	const {rows, page} = useSpringPage(result);

	const [searchParams] = useSearchParams();
	useEffect(() => {
		let lockId = searchParams.get("lockId");
		if (lockId != null) {
			setFilter({...filter, lockId: lockId});
		}
	}, []);

	return (
		<View>
			<ViewHeader
				title={t("compartments.title")}
				actions={canCreate && (
					<Button color="primary" variant="contained" component={Link} to="new">
						{t("compartments.create.title")}
					</Button>
				)}
			/>
			<CompartmentFilter value={filter} onChange={setFilter} />
			<CompartmentTable
				loading={loading}
				error={error}
				rows={rows}
				page={page}
				onSort={onSort}
				onPageChange={onPageChange}
				storage={storage}
			/>
		</View>
	);
}
