import {CardContent, Chip, Grid} from "@mui/material";
import {Checkbox, Filter, SpringPageable} from "@variocube/app-ui";
import React from "react";
import {LocationQuery} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationTypeSelect} from "./LocationTypeSelect";

export type LocationFilter = Omit<LocationQuery, keyof SpringPageable>;

interface LocationFilterProps {
	value: LocationFilter;
	onChange: (value: LocationFilter) => any;
	onSearch: (value?: string) => any;
}

export function LocationFilter({value, onChange, onSearch}: LocationFilterProps) {
	const {t, s} = useLocalization();

	return (
		<Filter
			label={t("filter.title")}
			enableSearch
			onSearch={onSearch}
			active={[
				value.type && (
					<Chip
						key={"type"}
						label={`${t("locations.type")}: ${t(`locations.types.${value.type}`)}`}
						onDelete={() => onChange({...value, type: undefined})}
					/>
				),
				value.search && (
					<Chip
						key={"search"}
						label={`${t("filter.search")}: ${value.search}`}
						onDelete={() => onSearch(undefined)}
					/>
				),
				<Chip
					key={"isRoot"}
					label={value.isRoot ? t("locations.filter.onlyRootLocations") : t("locations.filter.allLocations")}
				/>,
			]}
			labels={s("filter")}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<LocationTypeSelect value={value.type} onChange={type => onChange({...value, type})} />
					</Grid>
					<Grid item xs={12} sm={6} alignContent={"center"}>
						<Checkbox
							label={t("locations.filter.showAllLocations")}
							value={!value.isRoot}
							onChange={isRoot => onChange({...value, isRoot: !isRoot})}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Filter>
	);
}
