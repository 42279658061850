import {Chip, ChipProps, Tooltip} from "@mui/material";
import {CloseIcon} from "@variocube/app-ui";
import React from "react";
import {Link} from "react-router-dom";
import {User, UserRef} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {UserIcon} from "../../theme";

export interface UserChipProps extends Omit<ChipProps, "label" | "icon"> {
	user: User | UserRef;
}

export function UserChip({user, ...chipProps}: UserChipProps) {
	return (
		<Chip
			icon={<UserIcon />}
			label={user.displayName}
			{...chipProps}
		/>
	);
}

export interface UserLinkChipProps {
	user?: User | UserRef;
	fallback?: string;
}

export function UserLinkChip({user, fallback}: UserLinkChipProps) {
	const {t} = useLocalization();
	const tenantId = useTenantId();
	return (
		<>
			{user
				&& (
					<Chip
						icon={<UserIcon />}
						label={user.displayName || user.username || user.email}
						component={Link}
						to={`/${tenantId}/users/${user.username}`}
						clickable
						variant="outlined"
						color="secondary"
					/>
				)}
			{!user && (
				<Tooltip title={t("users.deletedUser")}>
					<Chip
						icon={<CloseIcon />}
						label={fallback || t("users.deletedUser")}
						component={Link}
						to={""}
						variant="outlined"
						color="error"
					/>
				</Tooltip>
			)}
		</>
	);
}
