import {z} from "zod";

export type TenantMutation = z.infer<typeof TenantMutation>;
export const TenantMutation = z.object({
	name: z.string().optional(),
});

export type Tenant = z.infer<typeof Tenant>;
export const Tenant = z.object({
	id: z.string(),
	name: z.string(),
});

export type UserMutation = z.infer<typeof UserMutation>;
export const UserMutation = z.object({
	displayName: z.string().optional(),
	givenName: z.string().optional(),
	familyName: z.string().optional(),
	email: z.string().optional(),
});

export type User = z.infer<typeof User>;
export const User = z.object({
	username: z.string(),
	displayName: z.string(),
	givenName: z.union([z.string(), z.undefined()]).optional(),
	familyName: z.union([z.string(), z.undefined()]).optional(),
	email: z.union([z.string(), z.undefined()]).optional(),
	superUser: z.boolean(),
});

export type PasswordReset = z.infer<typeof PasswordReset>;
export const PasswordReset = z.object({
	password: z.union([z.string(), z.undefined()]).optional(),
	forcePasswordChange: z.boolean(),
});

export type SupportSettings = z.infer<typeof SupportSettings>;
export const SupportSettings = z.object({
	name: z.string().optional(),
	phone: z.string().optional(),
	email: z.string().optional(),
	info: z.string().optional(),
});

export type CompartmentSettings = z.infer<typeof CompartmentSettings>;
export const CompartmentSettings = z.object({
	compartmentSizes: z.array(z.string()),
	defaultSize: z.union([z.string(), z.undefined()]).optional(),
});

export type BookingSettings = z.infer<typeof BookingSettings>;
export const BookingSettings = z.object({
	enableUserBookingCreation: z.boolean(),
	maxBookingsPerUser: z.union([z.number(), z.undefined()]).optional(),
	temporary: z.union([z.boolean(), z.undefined()]).optional(),
});

export type TenantAuditSettings = z.infer<typeof TenantAuditSettings>;
export const TenantAuditSettings = z.object({
	auditBookingRetrieval: z.boolean(),
	confirmBookingRetrieval: z.boolean(),
	auditBookingRetrievalThrottle: z.string(),
});

export type TenantPlanMutation = z.infer<typeof TenantPlanMutation>;
export const TenantPlanMutation = z.object({
	planId: z.number(),
});

export type LockKeyMutation = z.infer<typeof LockKeyMutation>;
export const LockKeyMutation = z.object({
	key: z.string().optional(),
	timestamp: z.string().optional(),
});

export type LocationType = z.infer<typeof LocationType>;
export const LocationType = z.union([
	z.literal("COMPANY"),
	z.literal("COUNTRY"),
	z.literal("CITY"),
	z.literal("AREA"),
	z.literal("ESTATE"),
	z.literal("BUILDING"),
	z.literal("WING"),
	z.literal("FLOOR"),
	z.literal("ROOM"),
	z.literal("LOCKER_BANK"),
]);

export type LocationMutation = z.infer<typeof LocationMutation>;
export const LocationMutation = z.object({
	name: z.string(),
	type: z.union([LocationType, z.undefined()]).optional(),
	parentId: z.union([z.number(), z.undefined()]).optional(),
	enabled: z.boolean(),
});

export type LocationAncestry = {
	id: number;
	name: string;
	enabled: boolean;
	type?:
		| ("COMPANY" | "COUNTRY" | "CITY" | "AREA" | "ESTATE" | "BUILDING" | "WING" | "FLOOR" | "ROOM" | "LOCKER_BANK")
		| undefined;
	parent?: LocationAncestry | undefined;
};
export const LocationAncestry: z.ZodType<LocationAncestry> = z.lazy(() =>
	z.object({
		id: z.number(),
		name: z.string(),
		enabled: z.boolean(),
		type: z.union([LocationType, z.undefined()]).optional(),
		parent: z.union([LocationAncestry, z.undefined()]).optional(),
	})
);
export type TenantRef = z.infer<typeof TenantRef>;
export const TenantRef = z.object({
	id: z.string(),
	name: z.string(),
});

export type Location = z.infer<typeof Location>;
export const Location = z.object({
	id: z.number(),
	name: z.string(),
	type: z.union([LocationType, z.undefined()]).optional(),
	enabled: z.boolean(),
	parent: z.union([LocationAncestry, z.undefined()]).optional(),
	tenant: z.union([TenantRef, z.undefined()]).optional(),
	hasChildren: z.union([z.boolean(), z.undefined()]).optional(),
	hasCompartments: z.union([z.boolean(), z.undefined()]).optional(),
});

export type GroupMutation = z.infer<typeof GroupMutation>;
export const GroupMutation = z.object({
	name: z.string(),
	defaultGroup: z.boolean(),
});

export type Group = z.infer<typeof Group>;
export const Group = z.object({
	id: z.number(),
	name: z.string(),
	defaultGroup: z.boolean(),
});

export type RoleType = z.infer<typeof RoleType>;
export const RoleType = z.union([z.literal("SYSTEM"), z.literal("CUSTOM")]);

export type SystemRole = z.infer<typeof SystemRole>;
export const SystemRole = z.union([
	z.literal("ADMIN"),
	z.literal("MANAGER"),
	z.literal("SELF_MANAGING_USER"),
	z.literal("USER"),
]);

export type Permission = z.infer<typeof Permission>;
export const Permission = z.union([
	z.literal("LOCATION_CREATE"),
	z.literal("LOCATION_EDIT"),
	z.literal("LOCATION_DELETE"),
	z.literal("COMPARTMENT_CREATE"),
	z.literal("COMPARTMENT_EDIT"),
	z.literal("COMPARTMENT_DELETE"),
	z.literal("LOCK_MANAGE"),
	z.literal("TENANT_USER_CREATE"),
	z.literal("TENANT_USER_EDIT"),
	z.literal("TENANT_USER_DELETE"),
	z.literal("TENANT_USER_READ"),
	z.literal("GROUP_CREATE"),
	z.literal("GROUP_EDIT"),
	z.literal("GROUP_DELETE"),
	z.literal("GROUP_READ"),
	z.literal("BOOKING_CREATE"),
	z.literal("BOOKING_CREATE_OWN"),
	z.literal("BOOKING_EDIT"),
	z.literal("BOOKING_DELETE"),
	z.literal("BOOKING_READ"),
	z.literal("SETTINGS_EDIT"),
	z.literal("STATISTICS_READ"),
	z.literal("AUDIT_LOG_READ"),
]);

export type GroupRoleMutation = z.infer<typeof GroupRoleMutation>;
export const GroupRoleMutation = z.object({
	locationId: z.union([z.number(), z.undefined()]).optional(),
	roleType: RoleType,
	systemRole: z.union([SystemRole, z.undefined()]).optional(),
	permissions: z.union([z.array(Permission), z.undefined()]).optional(),
});

export type GroupRole = z.infer<typeof GroupRole>;
export const GroupRole = z.object({
	id: z.number(),
	location: z.union([LocationAncestry, z.undefined()]).optional(),
	roleType: RoleType,
	systemRole: z.union([SystemRole, z.undefined()]).optional(),
	permissions: z.union([z.array(Permission), z.undefined()]).optional(),
	effectivePermissions: z.array(Permission),
});

export type CompartmentMutation = z.infer<typeof CompartmentMutation>;
export const CompartmentMutation = z.object({
	number: z.string(),
	size: z.union([z.string(), z.undefined()]).optional(),
	enabled: z.boolean(),
});

export type LockStatus = z.infer<typeof LockStatus>;
export const LockStatus = z.union([z.literal("OPEN"), z.literal("CLOSED"), z.literal("UNKNOWN")]);

export type LockRef = z.infer<typeof LockRef>;
export const LockRef = z.object({
	id: z.string(),
	status: LockStatus,
});

export type Compartment = z.infer<typeof Compartment>;
export const Compartment = z.object({
	id: z.number(),
	number: z.string(),
	tenant: TenantRef,
	location: LocationAncestry,
	enabled: z.boolean(),
	booked: z.boolean(),
	size: z.union([z.string(), z.undefined()]).optional(),
	lock: z.union([LockRef, z.undefined()]).optional(),
	bookable: z.boolean(),
});

export type CompartmentLockMutation = z.infer<typeof CompartmentLockMutation>;
export const CompartmentLockMutation = z.object({
	lockId: z.string().optional(),
});

export type CompartmentLocationMutation = z.infer<typeof CompartmentLocationMutation>;
export const CompartmentLocationMutation = z.object({
	locationId: z.number(),
});

export type BookingEndMutation = z.infer<typeof BookingEndMutation>;
export const BookingEndMutation = z.object({
	until: z.string().optional(),
});

export type UserRef = z.infer<typeof UserRef>;
export const UserRef = z.object({
	username: z.string(),
	displayName: z.string(),
	givenName: z.union([z.string(), z.undefined()]).optional(),
	familyName: z.union([z.string(), z.undefined()]).optional(),
	email: z.union([z.string(), z.undefined()]).optional(),
});

export type CompartmentRef = z.infer<typeof CompartmentRef>;
export const CompartmentRef = z.object({
	id: z.number(),
	number: z.string(),
});

export type BookingStatus = z.infer<typeof BookingStatus>;
export const BookingStatus = z.union([z.literal("ACTIVE"), z.literal("ENDED"), z.literal("FUTURE")]);

export type Booking = z.infer<typeof Booking>;
export const Booking = z.object({
	id: z.number(),
	users: z.array(UserRef),
	compartment: CompartmentRef,
	location: LocationAncestry,
	lock: z.union([LockRef, z.undefined()]).optional(),
	from: z.string(),
	until: z.union([z.string(), z.undefined()]).optional(),
	name: z.union([z.string(), z.undefined()]).optional(),
	selfManaged: z.boolean(),
	temporary: z.boolean(),
	status: BookingStatus,
});

export type BookingNameMutation = z.infer<typeof BookingNameMutation>;
export const BookingNameMutation = z.object({
	name: z.string().optional(),
});

export type Feature = z.infer<typeof Feature>;
export const Feature = z.union([
	z.literal("DETAILED_STATISTICS"),
	z.literal("SINGLE_SIGN_ON"),
	z.literal("LOCATION_BASED_PERMISSIONS"),
	z.literal("ASSIGN_TEAM_LOCKERS"),
]);

export type PlanMutation = z.infer<typeof PlanMutation>;
export const PlanMutation = z.object({
	name: z.string(),
	features: z.array(Feature),
});

export type Plan = z.infer<typeof Plan>;
export const Plan = z.object({
	id: z.number(),
	name: z.string(),
	features: z.array(Feature),
});

export type TenantCreation = z.infer<typeof TenantCreation>;
export const TenantCreation = z.object({
	id: z.string().optional(),
	name: z.string().optional(),
	userGroupName: z.string().optional(),
	userGroupSelfManaging: z.boolean().optional(),
	adminGroupName: z.string().optional(),
	locationName: z.string().optional(),
	planId: z.number().optional(),
});

export type TenantUserCreation = z.infer<typeof TenantUserCreation>;
export const TenantUserCreation = z.object({
	displayName: z.string(),
	givenName: z.string(),
	familyName: z.string(),
	email: z.string(),
	password: z.union([z.string(), z.undefined()]).optional(),
	forcePasswordChange: z.boolean(),
});

export type GroupRef = z.infer<typeof GroupRef>;
export const GroupRef = z.object({
	id: z.number(),
	name: z.string(),
});

export type TenantUser = z.infer<typeof TenantUser>;
export const TenantUser = z.object({
	id: z.number(),
	user: UserRef,
	tenant: TenantRef,
	groups: z.array(GroupRef),
});

export type LockEventCreation = z.infer<typeof LockEventCreation>;
export const LockEventCreation = z.object({
	status: LockStatus,
	timestamp: z.union([z.string(), z.undefined()]).optional(),
});

export type LocationCreation = z.infer<typeof LocationCreation>;
export const LocationCreation = z.object({
	name: z.string(),
	type: z.union([LocationType, z.undefined()]).optional(),
	parentId: z.union([z.number(), z.undefined()]).optional(),
	enabled: z.boolean(),
});

export type CompartmentCreation = z.infer<typeof CompartmentCreation>;
export const CompartmentCreation = z.object({
	number: z.string(),
	size: z.union([z.string(), z.undefined()]).optional(),
	enabled: z.union([z.boolean(), z.undefined()]).optional(),
	locationId: z.number(),
	lock: z.union([z.string(), z.undefined()]).optional(),
});

export type BookingCreation = z.infer<typeof BookingCreation>;
export const BookingCreation = z.object({
	compartmentId: z.number(),
	users: z.array(z.string()),
	from: z.string(),
	until: z.union([z.string(), z.undefined()]).optional(),
	name: z.union([z.string(), z.undefined()]).optional(),
	selfManaged: z.boolean(),
	temporary: z.boolean(),
	own: z.union([z.boolean(), z.undefined()]).optional(),
});

export type PlanCreation = z.infer<typeof PlanCreation>;
export const PlanCreation = z.object({
	name: z.string(),
	features: z.array(Feature),
});

export type TokenQuery = z.infer<typeof TokenQuery>;
export const TokenQuery = z.object({
	code: z.string(),
	redirectUri: z.string(),
});

export type AuthenticationToken = z.infer<typeof AuthenticationToken>;
export const AuthenticationToken = z.object({
	id_token: z.string().optional(),
	access_token: z.string().optional(),
	refresh_token: z.string().optional(),
	expires_in: z.number().optional(),
	token_type: z.string().optional(),
});

export type RefreshTokenQuery = z.infer<typeof RefreshTokenQuery>;
export const RefreshTokenQuery = z.object({
	refreshToken: z.string(),
});

export type SortObject = z.infer<typeof SortObject>;
export const SortObject = z.object({
	direction: z.string().optional(),
	nullHandling: z.string().optional(),
	ascending: z.boolean().optional(),
	property: z.string().optional(),
	ignoreCase: z.boolean().optional(),
});

export type PageableObject = z.infer<typeof PageableObject>;
export const PageableObject = z.object({
	paged: z.boolean().optional(),
	pageNumber: z.number().optional(),
	pageSize: z.number().optional(),
	unpaged: z.boolean().optional(),
	offset: z.number().optional(),
	sort: z.array(SortObject).optional(),
});

export type PageTenant = z.infer<typeof PageTenant>;
export const PageTenant = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(Tenant).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type PageTenantUser = z.infer<typeof PageTenantUser>;
export const PageTenantUser = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(TenantUser).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type PageBooking = z.infer<typeof PageBooking>;
export const PageBooking = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(Booking).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type AuditAction = z.infer<typeof AuditAction>;
export const AuditAction = z.union([
	z.literal("TENANT_CREATED"),
	z.literal("TENANT_UPDATED"),
	z.literal("TENANT_DELETED"),
	z.literal("TENANT_AUDIT_SETTINGS_UPDATED"),
	z.literal("TENANT_BOOKING_SETTINGS_UPDATED"),
	z.literal("TENANT_COMPARTMENT_SETTINGS_UPDATED"),
	z.literal("TENANT_SUPPORT_SETTINGS_UPDATED"),
	z.literal("TENANT_PLAN_UPDATED"),
	z.literal("LOCATION_CREATED"),
	z.literal("LOCATION_UPDATED"),
	z.literal("LOCATION_DELETED"),
	z.literal("LOCATION_BOOKING_SETTINGS_UPDATED"),
	z.literal("LOCATION_COMPARTMENT_SETTINGS_UPDATED"),
	z.literal("COMPARTMENT_CREATED"),
	z.literal("COMPARTMENT_UPDATED"),
	z.literal("COMPARTMENT_DELETED"),
	z.literal("COMPARTMENT_LOCK_UPDATED"),
	z.literal("COMPARTMENT_LOCATION_UPDATED"),
	z.literal("COMPARTMENT_BOOKINGS_RETRIEVED"),
	z.literal("BOOKING_CREATED"),
	z.literal("BOOKING_UPDATED"),
	z.literal("BOOKING_DELETED"),
	z.literal("BOOKING_USER_ADDED"),
	z.literal("BOOKING_USER_REMOVED"),
	z.literal("BOOKINGS_RETRIEVED"),
	z.literal("GROUP_CREATED"),
	z.literal("GROUP_UPDATED"),
	z.literal("GROUP_DELETED"),
	z.literal("GROUP_ROLE_CREATED"),
	z.literal("GROUP_ROLE_UPDATED"),
	z.literal("GROUP_ROLE_DELETED"),
	z.literal("TENANT_USER_CREATED"),
	z.literal("TENANT_USER_UPDATED"),
	z.literal("TENANT_USER_DELETED"),
	z.literal("TENANT_USER_GROUP_ADDED"),
	z.literal("TENANT_USER_GROUP_REMOVED"),
	z.literal("USER_LOGGED_IN"),
	z.literal("USER_BOOKINGS_RETRIEVED"),
	z.literal("LOCK_OPENED"),
	z.literal("LOCK_CLOSED"),
	z.literal("LOCK_STATUS_UNKNOWN"),
	z.literal("LOCK_CREATED"),
	z.literal("LOCK_USER_KEY_UPDATED"),
	z.literal("LOCK_TENANT_KEY_UPDATED"),
	z.literal("LOCK_KEYS_RESET"),
	z.literal("TENANT_LOCK_ACQUIRED"),
	z.literal("TENANT_LOCK_RELEASED"),
	z.literal("PLAN_UPDATED"),
	z.literal("PLAN_DELETED"),
	z.literal("PLAN_CREATED"),
]);

export type JsonNode = z.infer<typeof JsonNode>;
export const JsonNode = z.unknown();

export type AuditLogEntry = z.infer<typeof AuditLogEntry>;
export const AuditLogEntry = z.object({
	id: z.number(),
	created: z.string(),
	eventTimestamp: z.string(),
	action: AuditAction,
	actor: z.union([z.string(), z.undefined()]).optional(),
	user: z.union([User, z.undefined()]).optional(),
	data: z.union([JsonNode, z.undefined()]).optional(),
	patch: z.union([JsonNode, z.undefined()]).optional(),
	compartmentId: z.union([z.number(), z.undefined()]).optional(),
	locationId: z.union([z.number(), z.undefined()]).optional(),
	username: z.union([z.string(), z.undefined()]).optional(),
	groupId: z.union([z.number(), z.undefined()]).optional(),
	groupRoleId: z.union([z.number(), z.undefined()]).optional(),
	bookingId: z.union([z.number(), z.undefined()]).optional(),
	lockId: z.union([z.string(), z.undefined()]).optional(),
});

export type PageAuditLogEntry = z.infer<typeof PageAuditLogEntry>;
export const PageAuditLogEntry = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(AuditLogEntry).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type TenantSettings = z.infer<typeof TenantSettings>;
export const TenantSettings = z.object({
	booking: BookingSettings,
	audit: TenantAuditSettings,
	compartment: CompartmentSettings,
	support: SupportSettings,
});

export type LocationRef = z.infer<typeof LocationRef>;
export const LocationRef = z.object({
	id: z.number(),
	name: z.string(),
});

export type UserRole = z.infer<typeof UserRole>;
export const UserRole = z.object({
	id: z.number(),
	tenant: TenantRef,
	location: z.union([LocationRef, z.undefined()]).optional(),
	role: SystemRole,
	username: z.string(),
});

export type UserPermissions = z.infer<typeof UserPermissions>;
export const UserPermissions = z.object({
	location: z.union([LocationRef, z.undefined()]).optional(),
	permissions: z.array(Permission),
});

export type Lock = z.infer<typeof Lock>;
export const Lock = z.object({
	id: z.string(),
	status: LockStatus,
	compartment: z.union([CompartmentRef, z.undefined()]).optional(),
});

export type LockKey = z.infer<typeof LockKey>;
export const LockKey = z.object({
	key: z.string(),
	nextKey: z.union([z.string(), z.undefined()]).optional(),
});

export type LockKeys = z.infer<typeof LockKeys>;
export const LockKeys = z.object({
	user: LockKey,
	tenant: LockKey,
});

export type LocationListItem = z.infer<typeof LocationListItem>;
export const LocationListItem = z.object({
	id: z.number(),
	name: z.string(),
	type: z.union([LocationType, z.undefined()]).optional(),
	enabled: z.boolean(),
	parent: z.union([LocationAncestry, z.undefined()]).optional(),
	tenant: z.union([TenantRef, z.undefined()]).optional(),
});

export type PageLocationListItem = z.infer<typeof PageLocationListItem>;
export const PageLocationListItem = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(LocationListItem).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type LocationSettings = z.infer<typeof LocationSettings>;
export const LocationSettings = z.object({
	booking: BookingSettings,
	compartment: CompartmentSettings,
});

export type PageCompartment = z.infer<typeof PageCompartment>;
export const PageCompartment = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(Compartment).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type CompartmentTemplate = z.infer<typeof CompartmentTemplate>;
export const CompartmentTemplate = z.object({
	number: z.string(),
	sizes: z.array(z.string()),
	defaultSize: z.union([z.string(), z.undefined()]).optional(),
});

export type LocationTree = {
	id: number;
	name: string;
	type?:
		| ("COMPANY" | "COUNTRY" | "CITY" | "AREA" | "ESTATE" | "BUILDING" | "WING" | "FLOOR" | "ROOM" | "LOCKER_BANK")
		| undefined;
	enabled: boolean;
	children: Array<LocationTree>;
};
export const LocationTree: z.ZodType<LocationTree> = z.lazy(() =>
	z.object({
		id: z.number(),
		name: z.string(),
		type: z.union([LocationType, z.undefined()]).optional(),
		enabled: z.boolean(),
		children: z.array(LocationTree),
	})
);
export type Utilization = z.infer<typeof Utilization>;
export const Utilization = z.object({
	booked: z.number(),
	disabled: z.number(),
	available: z.union([z.number(), z.undefined()]).optional(),
	total: z.union([z.number(), z.undefined()]).optional(),
});

export type PageGroup = z.infer<typeof PageGroup>;
export const PageGroup = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(Group).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type PageUser = z.infer<typeof PageUser>;
export const PageUser = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(User).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type CompartmentStatus = z.infer<typeof CompartmentStatus>;
export const CompartmentStatus = z.union([z.literal("BOOKED"), z.literal("AVAILABLE")]);

export type BookingCountPerDayStatistics = z.infer<typeof BookingCountPerDayStatistics>;
export const BookingCountPerDayStatistics = z.object({
	date: z.string().optional(),
	count: z.number().optional(),
});

export type BookingKey = z.infer<typeof BookingKey>;
export const BookingKey = z.object({
	bookingId: z.number().optional(),
	lockKey: LockKey.optional(),
});

export type BookingKeys = z.infer<typeof BookingKeys>;
export const BookingKeys = z.object({
	bookingKeyList: z.array(BookingKey).optional(),
});

export type PagePlan = z.infer<typeof PagePlan>;
export const PagePlan = z.object({
	totalPages: z.number().optional(),
	totalElements: z.number().optional(),
	pageable: PageableObject.optional(),
	first: z.boolean().optional(),
	last: z.boolean().optional(),
	size: z.number().optional(),
	content: z.array(Plan).optional(),
	number: z.number().optional(),
	sort: z.array(SortObject).optional(),
	numberOfElements: z.number().optional(),
	empty: z.boolean().optional(),
});

export type LockScan = z.infer<typeof LockScan>;
export const LockScan = z.object({
	tenant: TenantRef.optional(),
});

export type get_GetTenant = typeof get_GetTenant;
export const get_GetTenant = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: Tenant,
};

export type put_UpdateTenant = typeof put_UpdateTenant;
export const put_UpdateTenant = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: TenantMutation,
	}),
	response: Tenant,
};

export type delete_DeleteTenant = typeof delete_DeleteTenant;
export const delete_DeleteTenant = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetUser = typeof get_GetUser;
export const get_GetUser = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: TenantUser,
};

export type put_UpdateUser = typeof put_UpdateUser;
export const put_UpdateUser = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
		body: UserMutation,
	}),
	response: User,
};

export type delete_DeleteUser = typeof delete_DeleteUser;
export const delete_DeleteUser = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type put_ResetUserPassword = typeof put_ResetUserPassword;
export const put_ResetUserPassword = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/password"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
		body: PasswordReset,
	}),
	response: z.unknown(),
};

export type get_GetTenantSupportSettings = typeof get_GetTenantSupportSettings;
export const get_GetTenantSupportSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/settings/support"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: SupportSettings,
};

export type put_UpdateTenantSupportSettings = typeof put_UpdateTenantSupportSettings;
export const put_UpdateTenantSupportSettings = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/settings/support"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: SupportSettings,
	}),
	response: z.unknown(),
};

export type get_GetTenantCompartmentSettings = typeof get_GetTenantCompartmentSettings;
export const get_GetTenantCompartmentSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/settings/compartment"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: CompartmentSettings,
};

export type put_UpdateTenantCompartmentSettings = typeof put_UpdateTenantCompartmentSettings;
export const put_UpdateTenantCompartmentSettings = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/settings/compartment"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: CompartmentSettings,
	}),
	response: z.unknown(),
};

export type get_GetTenantBookingSettings = typeof get_GetTenantBookingSettings;
export const get_GetTenantBookingSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/settings/booking"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: BookingSettings,
};

export type put_UpdateTenantBookingSettings = typeof put_UpdateTenantBookingSettings;
export const put_UpdateTenantBookingSettings = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/settings/booking"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: BookingSettings,
	}),
	response: z.unknown(),
};

export type get_GetTenantAuditSettings = typeof get_GetTenantAuditSettings;
export const get_GetTenantAuditSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/settings/audit"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: TenantAuditSettings,
};

export type put_UpdateTenantAuditSettings = typeof put_UpdateTenantAuditSettings;
export const put_UpdateTenantAuditSettings = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/settings/audit"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: TenantAuditSettings,
	}),
	response: z.unknown(),
};

export type get_GetTenantPlan = typeof get_GetTenantPlan;
export const get_GetTenantPlan = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/plan"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: Plan,
};

export type put_UpdateTenantPlan = typeof put_UpdateTenantPlan;
export const put_UpdateTenantPlan = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/plan"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: TenantPlanMutation,
	}),
	response: z.unknown(),
};

export type put_UpdateUserLockKey = typeof put_UpdateUserLockKey;
export const put_UpdateUserLockKey = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/keys/user"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
		body: LockKeyMutation,
	}),
	response: z.unknown(),
};

export type put_UpdateTenantLockKey = typeof put_UpdateTenantLockKey;
export const put_UpdateTenantLockKey = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/keys/tenant"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
		body: LockKeyMutation,
	}),
	response: z.unknown(),
};

export type get_GetLocation = typeof get_GetLocation;
export const get_GetLocation = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: Location,
};

export type put_UpdateLocation = typeof put_UpdateLocation;
export const put_UpdateLocation = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
		body: LocationMutation,
	}),
	response: Location,
};

export type delete_DeleteLocation = typeof delete_DeleteLocation;
export const delete_DeleteLocation = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetLocationCompartmentSettings = typeof get_GetLocationCompartmentSettings;
export const get_GetLocationCompartmentSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/settings/compartment"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: CompartmentSettings,
};

export type put_UpdateLocationCompartmentSettings = typeof put_UpdateLocationCompartmentSettings;
export const put_UpdateLocationCompartmentSettings = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/settings/compartment"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
		body: CompartmentSettings,
	}),
	response: z.unknown(),
};

export type get_GetLocationBookingSettings = typeof get_GetLocationBookingSettings;
export const get_GetLocationBookingSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/settings/booking"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: BookingSettings,
};

export type put_UpdateLocationBookingSettings = typeof put_UpdateLocationBookingSettings;
export const put_UpdateLocationBookingSettings = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/settings/booking"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
		body: BookingSettings,
	}),
	response: z.unknown(),
};

export type get_GetGroup = typeof get_GetGroup;
export const get_GetGroup = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/groups/{groupId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			groupId: z.number(),
		}),
	}),
	response: Group,
};

export type put_UpdateGroup = typeof put_UpdateGroup;
export const put_UpdateGroup = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/groups/{groupId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			groupId: z.number(),
		}),
		body: GroupMutation,
	}),
	response: Group,
};

export type delete_DeleteGroup = typeof delete_DeleteGroup;
export const delete_DeleteGroup = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/groups/{groupId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			groupId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type put_UpdateGroupRole = typeof put_UpdateGroupRole;
export const put_UpdateGroupRole = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/groups/{groupId}/roles/{groupRoleId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			groupId: z.number(),
			groupRoleId: z.number(),
		}),
		body: GroupRoleMutation,
	}),
	response: GroupRole,
};

export type delete_DeleteGroupRole = typeof delete_DeleteGroupRole;
export const delete_DeleteGroupRole = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/groups/{groupId}/roles/{groupRoleId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			groupId: z.number(),
			groupRoleId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetCompartment = typeof get_GetCompartment;
export const get_GetCompartment = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
	}),
	response: Compartment,
};

export type put_UpdateCompartment = typeof put_UpdateCompartment;
export const put_UpdateCompartment = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
		body: CompartmentMutation,
	}),
	response: Compartment,
};

export type delete_DeleteCompartment = typeof delete_DeleteCompartment;
export const delete_DeleteCompartment = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type put_AssignCompartmentLock = typeof put_AssignCompartmentLock;
export const put_AssignCompartmentLock = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}/lock"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
		body: CompartmentLockMutation,
	}),
	response: Compartment,
};

export type put_AssignCompartmentLocation = typeof put_AssignCompartmentLocation;
export const put_AssignCompartmentLocation = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}/location"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
		body: CompartmentLocationMutation,
	}),
	response: Compartment,
};

export type put_UpdateBookingUntil = typeof put_UpdateBookingUntil;
export const put_UpdateBookingUntil = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/until"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
		body: BookingEndMutation,
	}),
	response: Booking,
};

export type put_UpdateBookingName = typeof put_UpdateBookingName;
export const put_UpdateBookingName = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/name"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
		body: BookingNameMutation,
	}),
	response: Booking,
};

export type get_GetBookingLockKey = typeof get_GetBookingLockKey;
export const get_GetBookingLockKey = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/key"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
	}),
	response: LockKey,
};

export type put_UpdateBookingLockKey = typeof put_UpdateBookingLockKey;
export const put_UpdateBookingLockKey = {
	method: z.literal("PUT"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/key"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
		body: LockKeyMutation,
	}),
	response: z.unknown(),
};

export type get_GetPlan = typeof get_GetPlan;
export const get_GetPlan = {
	method: z.literal("GET"),
	path: z.literal("/api/plans/{planId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			planId: z.number(),
		}),
	}),
	response: Plan,
};

export type put_UpdatePlan = typeof put_UpdatePlan;
export const put_UpdatePlan = {
	method: z.literal("PUT"),
	path: z.literal("/api/plans/{planId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			planId: z.number(),
		}),
		body: PlanMutation,
	}),
	response: Plan,
};

export type delete_DeletePlan = typeof delete_DeletePlan;
export const delete_DeletePlan = {
	method: z.literal("DELETE"),
	path: z.literal("/api/plans/{planId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			planId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type get_QueryTenants = typeof get_QueryTenants;
export const get_QueryTenants = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			search: z.string().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
	}),
	response: PageTenant,
};

export type post_CreateTenant = typeof post_CreateTenant;
export const post_CreateTenant = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		body: TenantCreation,
	}),
	response: Tenant,
};

export type get_QueryUsers = typeof get_QueryUsers;
export const get_QueryUsers = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			search: z.string().optional(),
			group: z.number().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageTenantUser,
};

export type post_CreateUser = typeof post_CreateUser;
export const post_CreateUser = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/users"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: TenantUserCreation,
	}),
	response: TenantUser,
};

export type post_AddUserToGroup = typeof post_AddUserToGroup;
export const post_AddUserToGroup = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/groups/{groupId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
			groupId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type delete_RemoveUserFromGroup = typeof delete_RemoveUserFromGroup;
export const delete_RemoveUserFromGroup = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/groups/{groupId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
			groupId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type post_ReportLockEvent = typeof post_ReportLockEvent;
export const post_ReportLockEvent = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/events"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
		body: LockEventCreation,
	}),
	response: z.unknown(),
};

export type get_QueryLocations = typeof get_QueryLocations;
export const get_QueryLocations = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			search: z.string().optional(),
			parent: z.number().optional(),
			type: z
				.union([
					z.literal("COMPANY"),
					z.literal("COUNTRY"),
					z.literal("CITY"),
					z.literal("AREA"),
					z.literal("ESTATE"),
					z.literal("BUILDING"),
					z.literal("WING"),
					z.literal("FLOOR"),
					z.literal("ROOM"),
					z.literal("LOCKER_BANK"),
				])
				.optional(),
			isRoot: z.boolean().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageLocationListItem,
};

export type post_CreateLocation = typeof post_CreateLocation;
export const post_CreateLocation = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/locations"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: LocationCreation,
	}),
	response: Location,
};

export type get_QueryGroups = typeof get_QueryGroups;
export const get_QueryGroups = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/groups"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			search: z.string().optional(),
			defaultGroup: z.boolean().optional(),
			location: z.number().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageGroup,
};

export type post_CreateGroup = typeof post_CreateGroup;
export const post_CreateGroup = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/groups"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: GroupMutation,
	}),
	response: Group,
};

export type get_GetGroupRoles = typeof get_GetGroupRoles;
export const get_GetGroupRoles = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/groups/{groupId}/roles"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			groupId: z.number(),
		}),
	}),
	response: z.array(GroupRole),
};

export type post_CreateGroupRole = typeof post_CreateGroupRole;
export const post_CreateGroupRole = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/groups/{groupId}/roles"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			groupId: z.number(),
		}),
		body: GroupRoleMutation,
	}),
	response: GroupRole,
};

export type get_QueryCompartments = typeof get_QueryCompartments;
export const get_QueryCompartments = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartments"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			location: z.number().optional(),
			compartmentNumber: z.string().optional(),
			compartmentSize: z.string().optional(),
			status: z.union([z.literal("BOOKED"), z.literal("AVAILABLE")]).optional(),
			hasLock: z.boolean().optional(),
			lockId: z.string().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageCompartment,
};

export type post_CreateCompartment = typeof post_CreateCompartment;
export const post_CreateCompartment = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/compartments"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		body: CompartmentCreation,
	}),
	response: Compartment,
};

export type get_QueryBookings = typeof get_QueryBookings;
export const get_QueryBookings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/bookings"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			compartment: z.number().optional(),
			location: z.number().optional(),
			user: z.string().optional(),
			from: z.string().optional(),
			until: z.string().optional(),
			search: z.string().optional(),
			status: z.union([z.literal("ACTIVE"), z.literal("ENDED"), z.literal("FUTURE")]).optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageBooking,
};

export type post_CreateBooking = typeof post_CreateBooking;
export const post_CreateBooking = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/bookings"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
		header: z.object({
			"User-Agent": z.string().optional(),
		}),
		body: BookingCreation,
	}),
	response: Booking,
};

export type post_AddBookingUser = typeof post_AddBookingUser;
export const post_AddBookingUser = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
			username: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type delete_RemoveBookingUser = typeof delete_RemoveBookingUser;
export const delete_RemoveBookingUser = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
			username: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type post_AddBookingLockEvent = typeof post_AddBookingLockEvent;
export const post_AddBookingLockEvent = {
	method: z.literal("POST"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/lock/events"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
		body: LockEventCreation,
	}),
	response: z.unknown(),
};

export type get_QueryPlans = typeof get_QueryPlans;
export const get_QueryPlans = {
	method: z.literal("GET"),
	path: z.literal("/api/plans"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			search: z.string().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
	}),
	response: PagePlan,
};

export type post_CreatePlan = typeof post_CreatePlan;
export const post_CreatePlan = {
	method: z.literal("POST"),
	path: z.literal("/api/plans"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		body: PlanCreation,
	}),
	response: Plan,
};

export type post_GetAccessToken = typeof post_GetAccessToken;
export const post_GetAccessToken = {
	method: z.literal("POST"),
	path: z.literal("/api/auth/token"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		body: TokenQuery,
	}),
	response: AuthenticationToken,
};

export type post_RefreshToken = typeof post_RefreshToken;
export const post_RefreshToken = {
	method: z.literal("POST"),
	path: z.literal("/api/auth/refresh"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		body: RefreshTokenQuery,
	}),
	response: AuthenticationToken,
};

export type get_GetCurrentUser = typeof get_GetCurrentUser;
export const get_GetCurrentUser = {
	method: z.literal("GET"),
	path: z.literal("/api/user"),
	requestFormat: z.literal("json"),
	parameters: z.never(),
	response: User,
};

export type get_GetUserGroups = typeof get_GetUserGroups;
export const get_GetUserGroups = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/groups"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: z.array(Group),
};

export type get_GetUserBookings = typeof get_GetUserBookings;
export const get_GetUserBookings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/bookings"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: PageBooking,
};

export type get_GetUserAuditLog = typeof get_GetUserAuditLog;
export const get_GetUserAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/users/{username}/audit"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			username: z.string(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_GetTenantSettings = typeof get_GetTenantSettings;
export const get_GetTenantSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/settings"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: TenantSettings,
};

export type get_GetPermissions = typeof get_GetPermissions;
export const get_GetPermissions = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/permissions"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.array(UserPermissions),
};

export type get_GetLock = typeof get_GetLock;
export const get_GetLock = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: Lock,
};

export type delete_ReleaseLock = typeof delete_ReleaseLock;
export const delete_ReleaseLock = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetLockKeys = typeof get_GetLockKeys;
export const get_GetLockKeys = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/keys"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: LockKeys,
};

export type delete_ResetLockKeys = typeof delete_ResetLockKeys;
export const delete_ResetLockKeys = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/keys"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: LockKeys,
};

export type get_GetLockAuditLog = typeof get_GetLockAuditLog;
export const get_GetLockAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locks/{lockId}/audit"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			lockId: z.string(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_GetLocationSettings = typeof get_GetLocationSettings;
export const get_GetLocationSettings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/settings"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: LocationSettings,
};

export type get_GetLocationCompartments = typeof get_GetLocationCompartments;
export const get_GetLocationCompartments = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/compartments"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: PageCompartment,
};

export type get_GetLocationCompartmentTemplate = typeof get_GetLocationCompartmentTemplate;
export const get_GetLocationCompartmentTemplate = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/compartments/template"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: CompartmentTemplate,
};

export type get_GetLocationChildren = typeof get_GetLocationChildren;
export const get_GetLocationChildren = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/children"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: PageLocationListItem,
};

export type get_GetLocationAuditLog = typeof get_GetLocationAuditLog;
export const get_GetLocationAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/{locationId}/audit"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			locationId: z.number(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_GetTopLocations = typeof get_GetTopLocations;
export const get_GetTopLocations = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/locations/top"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			usage: z.union([z.literal("BOOKING"), z.literal("COMPARTMENT_CREATION")]).optional(),
			limit: z.number().optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.array(LocationAncestry),
};

export type get_GetLocationTrees = typeof get_GetLocationTrees;
export const get_GetLocationTrees = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/location-trees"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.array(LocationTree),
};

export type get_GetUtilization = typeof get_GetUtilization;
export const get_GetUtilization = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/kpis/utilization"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: Utilization,
};

export type get_GetTotalUsers = typeof get_GetTotalUsers;
export const get_GetTotalUsers = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/kpis/total-users"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.number(),
};

export type get_GetTotalCompartments = typeof get_GetTotalCompartments;
export const get_GetTotalCompartments = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/kpis/total-compartments"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.number(),
};

export type get_GetTotalBookings = typeof get_GetTotalBookings;
export const get_GetTotalBookings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/kpis/total-bookings"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.number(),
};

export type get_GetAverageBookingDuration = typeof get_GetAverageBookingDuration;
export const get_GetAverageBookingDuration = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/kpis/average-booking-duration"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.string(),
};

export type get_GetGroupUsers = typeof get_GetGroupUsers;
export const get_GetGroupUsers = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/groups/{groupId}/users"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			groupId: z.number(),
		}),
	}),
	response: PageUser,
};

export type get_GetGroupAuditLog = typeof get_GetGroupAuditLog;
export const get_GetGroupAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/groups/{groupId}/audit"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			groupId: z.number(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_GetCompartmentBookings = typeof get_GetCompartmentBookings;
export const get_GetCompartmentBookings = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}/bookings"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			status: z.union([z.literal("ACTIVE"), z.literal("ENDED"), z.literal("FUTURE")]).optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
	}),
	response: PageBooking,
};

export type get_GetCompartmentAuditLog = typeof get_GetCompartmentAuditLog;
export const get_GetCompartmentAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartments/{compartmentId}/audit"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			compartmentId: z.number(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_GetCompartmentSizes = typeof get_GetCompartmentSizes;
export const get_GetCompartmentSizes = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartments/sizes"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.array(z.string()),
};

export type get_FindCompartmentsWithoutUsage = typeof get_FindCompartmentsWithoutUsage;
export const get_FindCompartmentsWithoutUsage = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/compartment-statistics/get-compartments-without-usage"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			from: z.string().optional(),
			until: z.string().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageCompartment,
};

export type get_GetBooking = typeof get_GetBooking;
export const get_GetBooking = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
	}),
	response: Booking,
};

export type delete_DeleteBooking = typeof delete_DeleteBooking;
export const delete_DeleteBooking = {
	method: z.literal("DELETE"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
	}),
	response: z.unknown(),
};

export type get_GetBookingAuditLog = typeof get_GetBookingAuditLog;
export const get_GetBookingAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/bookings/{bookingId}/audit"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
			bookingId: z.number(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_FindBookingsWhichHaveBeenNeitherOpenedNorClosed =
	typeof get_FindBookingsWhichHaveBeenNeitherOpenedNorClosed;
export const get_FindBookingsWhichHaveBeenNeitherOpenedNorClosed = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/booking-statistics/get-bookings-neither-closed-or-opened"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			from: z.string().optional(),
			until: z.string().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageBooking,
};

export type get_FindBookingsWhichHaveBeenClosedButNotOpened = typeof get_FindBookingsWhichHaveBeenClosedButNotOpened;
export const get_FindBookingsWhichHaveBeenClosedButNotOpened = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/booking-statistics/get-bookings-closed-but-not-opened"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			from: z.string().optional(),
			until: z.string().optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageBooking,
};

export type get_FindBookingCountPerDay = typeof get_FindBookingCountPerDay;
export const get_FindBookingCountPerDay = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/booking-statistics/get-booking-count-per-day"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			from: z.string().optional(),
			until: z.string().optional(),
			locationId: z.number().optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: z.array(BookingCountPerDayStatistics),
};

export type get_GetBookingKeysForUser = typeof get_GetBookingKeysForUser;
export const get_GetBookingKeysForUser = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/booking-keys"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: BookingKeys,
};

export type get_QueryTenantAuditLog = typeof get_QueryTenantAuditLog;
export const get_QueryTenantAuditLog = {
	method: z.literal("GET"),
	path: z.literal("/api/tenants/{tenantId}/audit"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			from: z.string().optional(),
			until: z.string().optional(),
			booking: z.number().optional(),
			compartment: z.number().optional(),
			location: z.number().optional(),
			username: z.string().optional(),
			lock: z.string().optional(),
			groupId: z.number().optional(),
			groupRoleId: z.number().optional(),
			actor: z.string().optional(),
			action: z
				.union([
					z.literal("TENANT_CREATED"),
					z.literal("TENANT_UPDATED"),
					z.literal("TENANT_DELETED"),
					z.literal("TENANT_AUDIT_SETTINGS_UPDATED"),
					z.literal("TENANT_BOOKING_SETTINGS_UPDATED"),
					z.literal("TENANT_COMPARTMENT_SETTINGS_UPDATED"),
					z.literal("TENANT_SUPPORT_SETTINGS_UPDATED"),
					z.literal("TENANT_PLAN_UPDATED"),
					z.literal("LOCATION_CREATED"),
					z.literal("LOCATION_UPDATED"),
					z.literal("LOCATION_DELETED"),
					z.literal("LOCATION_BOOKING_SETTINGS_UPDATED"),
					z.literal("LOCATION_COMPARTMENT_SETTINGS_UPDATED"),
					z.literal("COMPARTMENT_CREATED"),
					z.literal("COMPARTMENT_UPDATED"),
					z.literal("COMPARTMENT_DELETED"),
					z.literal("COMPARTMENT_LOCK_UPDATED"),
					z.literal("COMPARTMENT_LOCATION_UPDATED"),
					z.literal("COMPARTMENT_BOOKINGS_RETRIEVED"),
					z.literal("BOOKING_CREATED"),
					z.literal("BOOKING_UPDATED"),
					z.literal("BOOKING_DELETED"),
					z.literal("BOOKING_USER_ADDED"),
					z.literal("BOOKING_USER_REMOVED"),
					z.literal("BOOKINGS_RETRIEVED"),
					z.literal("GROUP_CREATED"),
					z.literal("GROUP_UPDATED"),
					z.literal("GROUP_DELETED"),
					z.literal("GROUP_ROLE_CREATED"),
					z.literal("GROUP_ROLE_UPDATED"),
					z.literal("GROUP_ROLE_DELETED"),
					z.literal("TENANT_USER_CREATED"),
					z.literal("TENANT_USER_UPDATED"),
					z.literal("TENANT_USER_DELETED"),
					z.literal("TENANT_USER_GROUP_ADDED"),
					z.literal("TENANT_USER_GROUP_REMOVED"),
					z.literal("USER_LOGGED_IN"),
					z.literal("USER_BOOKINGS_RETRIEVED"),
					z.literal("LOCK_OPENED"),
					z.literal("LOCK_CLOSED"),
					z.literal("LOCK_STATUS_UNKNOWN"),
					z.literal("LOCK_CREATED"),
					z.literal("LOCK_USER_KEY_UPDATED"),
					z.literal("LOCK_TENANT_KEY_UPDATED"),
					z.literal("LOCK_KEYS_RESET"),
					z.literal("TENANT_LOCK_ACQUIRED"),
					z.literal("TENANT_LOCK_RELEASED"),
					z.literal("PLAN_UPDATED"),
					z.literal("PLAN_DELETED"),
					z.literal("PLAN_CREATED"),
				])
				.optional(),
			page: z.number().optional(),
			size: z.number().optional(),
			sort: z.array(z.string()).optional(),
		}),
		path: z.object({
			tenantId: z.string(),
		}),
	}),
	response: PageAuditLogEntry,
};

export type get_ScanLock = typeof get_ScanLock;
export const get_ScanLock = {
	method: z.literal("GET"),
	path: z.literal("/api/locks/{lockId}"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		path: z.object({
			lockId: z.string(),
		}),
	}),
	response: LockScan,
};

export type get_GetLatestFirmware = typeof get_GetLatestFirmware;
export const get_GetLatestFirmware = {
	method: z.literal("GET"),
	path: z.literal("/api/firmware/latest"),
	requestFormat: z.literal("json"),
	parameters: z.never(),
	response: z.unknown(),
};

export type get_Logout = typeof get_Logout;
export const get_Logout = {
	method: z.literal("GET"),
	path: z.literal("/api/auth/logout"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			redirectUri: z.string(),
		}),
	}),
	response: z.unknown(),
};

export type get_Login = typeof get_Login;
export const get_Login = {
	method: z.literal("GET"),
	path: z.literal("/api/auth/login"),
	requestFormat: z.literal("json"),
	parameters: z.object({
		query: z.object({
			email: z.union([z.string(), z.undefined()]),
			tenantId: z.union([z.string(), z.undefined()]),
			redirectUri: z.string(),
			state: z.union([z.string(), z.undefined()]),
		}),
	}),
	response: z.unknown(),
};

// <EndpointByMethod>
export const EndpointByMethod = {
	get: {
		"/api/tenants/{tenantId}": get_GetTenant,
		"/api/tenants/{tenantId}/users/{username}": get_GetUser,
		"/api/tenants/{tenantId}/settings/support": get_GetTenantSupportSettings,
		"/api/tenants/{tenantId}/settings/compartment": get_GetTenantCompartmentSettings,
		"/api/tenants/{tenantId}/settings/booking": get_GetTenantBookingSettings,
		"/api/tenants/{tenantId}/settings/audit": get_GetTenantAuditSettings,
		"/api/tenants/{tenantId}/plan": get_GetTenantPlan,
		"/api/tenants/{tenantId}/locations/{locationId}": get_GetLocation,
		"/api/tenants/{tenantId}/locations/{locationId}/settings/compartment": get_GetLocationCompartmentSettings,
		"/api/tenants/{tenantId}/locations/{locationId}/settings/booking": get_GetLocationBookingSettings,
		"/api/tenants/{tenantId}/groups/{groupId}": get_GetGroup,
		"/api/tenants/{tenantId}/compartments/{compartmentId}": get_GetCompartment,
		"/api/tenants/{tenantId}/bookings/{bookingId}/key": get_GetBookingLockKey,
		"/api/plans/{planId}": get_GetPlan,
		"/api/tenants": get_QueryTenants,
		"/api/tenants/{tenantId}/users": get_QueryUsers,
		"/api/tenants/{tenantId}/locations": get_QueryLocations,
		"/api/tenants/{tenantId}/groups": get_QueryGroups,
		"/api/tenants/{tenantId}/groups/{groupId}/roles": get_GetGroupRoles,
		"/api/tenants/{tenantId}/compartments": get_QueryCompartments,
		"/api/tenants/{tenantId}/bookings": get_QueryBookings,
		"/api/plans": get_QueryPlans,
		"/api/user": get_GetCurrentUser,
		"/api/tenants/{tenantId}/users/{username}/groups": get_GetUserGroups,
		"/api/tenants/{tenantId}/users/{username}/bookings": get_GetUserBookings,
		"/api/tenants/{tenantId}/users/{username}/audit": get_GetUserAuditLog,
		"/api/tenants/{tenantId}/settings": get_GetTenantSettings,
		"/api/tenants/{tenantId}/permissions": get_GetPermissions,
		"/api/tenants/{tenantId}/locks/{lockId}": get_GetLock,
		"/api/tenants/{tenantId}/locks/{lockId}/keys": get_GetLockKeys,
		"/api/tenants/{tenantId}/locks/{lockId}/audit": get_GetLockAuditLog,
		"/api/tenants/{tenantId}/locations/{locationId}/settings": get_GetLocationSettings,
		"/api/tenants/{tenantId}/locations/{locationId}/compartments": get_GetLocationCompartments,
		"/api/tenants/{tenantId}/locations/{locationId}/compartments/template": get_GetLocationCompartmentTemplate,
		"/api/tenants/{tenantId}/locations/{locationId}/children": get_GetLocationChildren,
		"/api/tenants/{tenantId}/locations/{locationId}/audit": get_GetLocationAuditLog,
		"/api/tenants/{tenantId}/locations/top": get_GetTopLocations,
		"/api/tenants/{tenantId}/location-trees": get_GetLocationTrees,
		"/api/tenants/{tenantId}/kpis/utilization": get_GetUtilization,
		"/api/tenants/{tenantId}/kpis/total-users": get_GetTotalUsers,
		"/api/tenants/{tenantId}/kpis/total-compartments": get_GetTotalCompartments,
		"/api/tenants/{tenantId}/kpis/total-bookings": get_GetTotalBookings,
		"/api/tenants/{tenantId}/kpis/average-booking-duration": get_GetAverageBookingDuration,
		"/api/tenants/{tenantId}/groups/{groupId}/users": get_GetGroupUsers,
		"/api/tenants/{tenantId}/groups/{groupId}/audit": get_GetGroupAuditLog,
		"/api/tenants/{tenantId}/compartments/{compartmentId}/bookings": get_GetCompartmentBookings,
		"/api/tenants/{tenantId}/compartments/{compartmentId}/audit": get_GetCompartmentAuditLog,
		"/api/tenants/{tenantId}/compartments/sizes": get_GetCompartmentSizes,
		"/api/tenants/{tenantId}/compartment-statistics/get-compartments-without-usage":
			get_FindCompartmentsWithoutUsage,
		"/api/tenants/{tenantId}/bookings/{bookingId}": get_GetBooking,
		"/api/tenants/{tenantId}/bookings/{bookingId}/audit": get_GetBookingAuditLog,
		"/api/tenants/{tenantId}/booking-statistics/get-bookings-neither-closed-or-opened":
			get_FindBookingsWhichHaveBeenNeitherOpenedNorClosed,
		"/api/tenants/{tenantId}/booking-statistics/get-bookings-closed-but-not-opened":
			get_FindBookingsWhichHaveBeenClosedButNotOpened,
		"/api/tenants/{tenantId}/booking-statistics/get-booking-count-per-day": get_FindBookingCountPerDay,
		"/api/tenants/{tenantId}/booking-keys": get_GetBookingKeysForUser,
		"/api/tenants/{tenantId}/audit": get_QueryTenantAuditLog,
		"/api/locks/{lockId}": get_ScanLock,
		"/api/firmware/latest": get_GetLatestFirmware,
		"/api/auth/logout": get_Logout,
		"/api/auth/login": get_Login,
	},
	put: {
		"/api/tenants/{tenantId}": put_UpdateTenant,
		"/api/tenants/{tenantId}/users/{username}": put_UpdateUser,
		"/api/tenants/{tenantId}/users/{username}/password": put_ResetUserPassword,
		"/api/tenants/{tenantId}/settings/support": put_UpdateTenantSupportSettings,
		"/api/tenants/{tenantId}/settings/compartment": put_UpdateTenantCompartmentSettings,
		"/api/tenants/{tenantId}/settings/booking": put_UpdateTenantBookingSettings,
		"/api/tenants/{tenantId}/settings/audit": put_UpdateTenantAuditSettings,
		"/api/tenants/{tenantId}/plan": put_UpdateTenantPlan,
		"/api/tenants/{tenantId}/locks/{lockId}/keys/user": put_UpdateUserLockKey,
		"/api/tenants/{tenantId}/locks/{lockId}/keys/tenant": put_UpdateTenantLockKey,
		"/api/tenants/{tenantId}/locations/{locationId}": put_UpdateLocation,
		"/api/tenants/{tenantId}/locations/{locationId}/settings/compartment": put_UpdateLocationCompartmentSettings,
		"/api/tenants/{tenantId}/locations/{locationId}/settings/booking": put_UpdateLocationBookingSettings,
		"/api/tenants/{tenantId}/groups/{groupId}": put_UpdateGroup,
		"/api/tenants/{tenantId}/groups/{groupId}/roles/{groupRoleId}": put_UpdateGroupRole,
		"/api/tenants/{tenantId}/compartments/{compartmentId}": put_UpdateCompartment,
		"/api/tenants/{tenantId}/compartments/{compartmentId}/lock": put_AssignCompartmentLock,
		"/api/tenants/{tenantId}/compartments/{compartmentId}/location": put_AssignCompartmentLocation,
		"/api/tenants/{tenantId}/bookings/{bookingId}/until": put_UpdateBookingUntil,
		"/api/tenants/{tenantId}/bookings/{bookingId}/name": put_UpdateBookingName,
		"/api/tenants/{tenantId}/bookings/{bookingId}/key": put_UpdateBookingLockKey,
		"/api/plans/{planId}": put_UpdatePlan,
	},
	delete: {
		"/api/tenants/{tenantId}": delete_DeleteTenant,
		"/api/tenants/{tenantId}/users/{username}": delete_DeleteUser,
		"/api/tenants/{tenantId}/locations/{locationId}": delete_DeleteLocation,
		"/api/tenants/{tenantId}/groups/{groupId}": delete_DeleteGroup,
		"/api/tenants/{tenantId}/groups/{groupId}/roles/{groupRoleId}": delete_DeleteGroupRole,
		"/api/tenants/{tenantId}/compartments/{compartmentId}": delete_DeleteCompartment,
		"/api/plans/{planId}": delete_DeletePlan,
		"/api/tenants/{tenantId}/users/{username}/groups/{groupId}": delete_RemoveUserFromGroup,
		"/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}": delete_RemoveBookingUser,
		"/api/tenants/{tenantId}/locks/{lockId}": delete_ReleaseLock,
		"/api/tenants/{tenantId}/locks/{lockId}/keys": delete_ResetLockKeys,
		"/api/tenants/{tenantId}/bookings/{bookingId}": delete_DeleteBooking,
	},
	post: {
		"/api/tenants": post_CreateTenant,
		"/api/tenants/{tenantId}/users": post_CreateUser,
		"/api/tenants/{tenantId}/users/{username}/groups/{groupId}": post_AddUserToGroup,
		"/api/tenants/{tenantId}/locks/{lockId}/events": post_ReportLockEvent,
		"/api/tenants/{tenantId}/locations": post_CreateLocation,
		"/api/tenants/{tenantId}/groups": post_CreateGroup,
		"/api/tenants/{tenantId}/groups/{groupId}/roles": post_CreateGroupRole,
		"/api/tenants/{tenantId}/compartments": post_CreateCompartment,
		"/api/tenants/{tenantId}/bookings": post_CreateBooking,
		"/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}": post_AddBookingUser,
		"/api/tenants/{tenantId}/bookings/{bookingId}/lock/events": post_AddBookingLockEvent,
		"/api/plans": post_CreatePlan,
		"/api/auth/token": post_GetAccessToken,
		"/api/auth/refresh": post_RefreshToken,
	},
};
export type EndpointByMethod = typeof EndpointByMethod;
// </EndpointByMethod>

// <EndpointByMethod.Shorthands>
export type GetEndpoints = EndpointByMethod["get"];
export type PutEndpoints = EndpointByMethod["put"];
export type DeleteEndpoints = EndpointByMethod["delete"];
export type PostEndpoints = EndpointByMethod["post"];
export type AllEndpoints = EndpointByMethod[keyof EndpointByMethod];
// </EndpointByMethod.Shorthands>

// <ApiClientTypes>
export type EndpointParameters = {
	body?: unknown;
	query?: Record<string, unknown>;
	header?: Record<string, unknown>;
	path?: Record<string, unknown>;
};

export type MutationMethod = "post" | "put" | "patch" | "delete";
export type Method = "get" | "head" | "options" | MutationMethod;

type RequestFormat = "json" | "form-data" | "form-url" | "binary" | "text";

export type DefaultEndpoint = {
	parameters?: EndpointParameters | undefined;
	response: unknown;
};

export type Endpoint<TConfig extends DefaultEndpoint = DefaultEndpoint> = {
	operationId: string;
	method: Method;
	path: string;
	requestFormat: RequestFormat;
	parameters?: TConfig["parameters"];
	meta: {
		alias: string;
		hasParameters: boolean;
		areParametersRequired: boolean;
	};
	response: TConfig["response"];
};

type Fetcher = (
	method: Method,
	url: string,
	parameters?: EndpointParameters | undefined,
) => Promise<Endpoint["response"]>;

type RequiredKeys<T> = {
	[P in keyof T]-?: undefined extends T[P] ? never : P;
}[keyof T];

type MaybeOptionalArg<T> = RequiredKeys<T> extends never ? [config?: T] : [config: T];

// </ApiClientTypes>

// <ApiClient>
export class ApiClient {
	baseUrl: string = "";

	constructor(public fetcher: Fetcher) {}

	setBaseUrl(baseUrl: string) {
		this.baseUrl = baseUrl;
		return this;
	}

	// <ApiClient.get>
	get<Path extends keyof GetEndpoints, TEndpoint extends GetEndpoints[Path]>(
		path: Path,
		...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
	): Promise<z.infer<TEndpoint["response"]>> {
		return this.fetcher("get", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
	}
	// </ApiClient.get>

	// <ApiClient.put>
	put<Path extends keyof PutEndpoints, TEndpoint extends PutEndpoints[Path]>(
		path: Path,
		...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
	): Promise<z.infer<TEndpoint["response"]>> {
		return this.fetcher("put", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
	}
	// </ApiClient.put>

	// <ApiClient.delete>
	delete<Path extends keyof DeleteEndpoints, TEndpoint extends DeleteEndpoints[Path]>(
		path: Path,
		...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
	): Promise<z.infer<TEndpoint["response"]>> {
		return this.fetcher("delete", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
	}
	// </ApiClient.delete>

	// <ApiClient.post>
	post<Path extends keyof PostEndpoints, TEndpoint extends PostEndpoints[Path]>(
		path: Path,
		...params: MaybeOptionalArg<z.infer<TEndpoint["parameters"]>>
	): Promise<z.infer<TEndpoint["response"]>> {
		return this.fetcher("post", this.baseUrl + path, params[0]) as Promise<z.infer<TEndpoint["response"]>>;
	}
	// </ApiClient.post>
}

export function createApiClient(fetcher: Fetcher, baseUrl?: string) {
	return new ApiClient(fetcher).setBaseUrl(baseUrl ?? "");
}

/**
 Example usage:
 const api = createApiClient((method, url, params) =>
   fetch(url, { method, body: JSON.stringify(params) }).then((res) => res.json()),
 );
 api.get("/users").then((users) => console.log(users));
 api.post("/users", { body: { name: "John" } }).then((user) => console.log(user));
 api.put("/users/:id", { path: { id: 1 }, body: { name: "John" } }).then((user) => console.log(user));
*/

// </ApiClient
