import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Checkbox, ErrorAlert, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {useTenantApi} from "../api";
import {usePlanApi} from "../api/plan";
import {useLocalization} from "../i18n";
import {slugify} from "../utils/slugify";
import {View, ViewHeader} from "./View";

export function TenantCreate() {
	const {t} = useLocalization();
	const {createTenant} = useTenantApi();
	const navigate = useNavigate();

	const [name, setName] = useState("");
	const [id, setId] = useState("");

	const [userGroupName, setUserGroupName] = useState(t("tenants.create.userGroupName.default"));
	const [adminGroupName, setAdminGroupName] = useState(t("tenants.create.adminGroupName.default"));
	const [userGroupSelfManaging, setUserGroupSelfManaging] = useState(true);
	const [locationName, setLocationName] = useState("");
	const [planId, setPlanId] = useState<number>();

	const [generateId, setGenerateId] = useState(true);
	const [generateLocationName, setGenerateLocationName] = useState(true);

	const {queryPlans} = usePlanApi();
	const {result: plans} = useAsync(() => queryPlans({}), []);

	const submit = useAsyncCallback(async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		await createTenant({
			id,
			name,
			userGroupName,
			userGroupSelfManaging,
			adminGroupName,
			locationName,
			planId,
		});
		navigate(`/${id}/settings`);
	});

	useEffect(() => {
		if (generateId) {
			setId(slugify(name));
		}
	}, [name, id, generateId]);

	useEffect(() => {
		if (generateLocationName) {
			setLocationName(name);
		}
	}, [name, generateLocationName]);

	function handleChangeId(id: string) {
		setGenerateId(!id || id == slugify(name));
		setId(id);
	}

	function handleLocationNameChange(locationName: string) {
		setGenerateLocationName(!locationName || locationName == name);
		setLocationName(locationName);
	}

	return (
		<Box py={4}>
			<View maxWidth="md">
				<Breadcrumbs>
					<BreadcrumbLink component={Link} to={".."} relative="path">{t("tenants.title")}</BreadcrumbLink>
					<BreadcrumbItem>{t("tenants.create.title")}</BreadcrumbItem>
				</Breadcrumbs>
				<ViewHeader title={t("tenants.create.title")} />
				<Card>
					<form onSubmit={submit.execute}>
						<CardHeader title={t("tenants.single")} />
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<TextField
										label={t("tenants.name.title")}
										helperText={t("tenants.name.helperText")}
										fullWidth
										value={name}
										onChange={setName}
										required
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										label={t("tenants.id.title")}
										helperText={t("tenants.id.helperText")}
										fullWidth
										inputProps={{
											pattern: "[a-z0-9-]+",
										}}
										value={id}
										onChange={handleChangeId}
										required
									/>
								</Grid>
							</Grid>
						</CardContent>
						<Divider />
						<CardHeader title={t("groups.title")} />
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<TextField
										label={t("tenants.create.userGroupName.title")}
										helperText={t("tenants.create.userGroupName.helperText")}
										fullWidth
										value={userGroupName}
										onChange={setUserGroupName}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Checkbox
										label={t("tenants.create.userGroupSelfManaging.title")}
										checked={userGroupSelfManaging}
										onChange={setUserGroupSelfManaging}
									/>
									<FormHelperText>
										{t("tenants.create.userGroupSelfManaging.helperText")}
									</FormHelperText>
								</Grid>
							</Grid>
						</CardContent>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<TextField
										label={t("tenants.create.adminGroupName.title")}
										helperText={t("tenants.create.adminGroupName.helperText")}
										fullWidth
										value={adminGroupName}
										onChange={setAdminGroupName}
									/>
								</Grid>
							</Grid>
						</CardContent>
						<Divider />
						<CardHeader title={t("locations.single")} />
						<CardContent>
							<TextField
								label={t("tenants.create.locationName.title")}
								helperText={t("tenants.create.locationName.helperText")}
								fullWidth
								value={locationName}
								onChange={handleLocationNameChange}
							/>
						</CardContent>

						<Divider />
						<CardHeader title={t("plans.single")} />
						<CardContent>
							<TextField
								select
								label={t("tenants.create.plan.title")}
								placeholder={t("tenants.create.plan.helperText")}
								helperText={t("tenants.create.plan.helperText")}
								value={planId}
								onChange={(value) => setPlanId(value as unknown as number)}
								fullWidth
							>
								{plans?.content?.map(plan => (
									<MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>
								))}
							</TextField>
						</CardContent>

						{submit.error && (
							<CardContent>
								<ErrorAlert error={submit.error} />
							</CardContent>
						)}
						<CardContent>
							<Button variant="outlined" color="primary" type="submit" disabled={submit.loading}>
								{t("tenants.create.title")}
							</Button>
						</CardContent>
					</form>
				</Card>
			</View>
		</Box>
	);
}
