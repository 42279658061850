import {Card, CardContent, CardHeader, Stack, styled} from "@mui/material";
import {Checkbox, EditForm, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {Location, LocationMutation, LocationType} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationSelect} from "./LocationSelect";
import {LocationTypeSelect} from "./LocationTypeSelect";

interface LocationEditFormProps {
	location?: Location;
	loading: boolean;
	onSave: (mutation: LocationMutation) => Promise<any>;
}

const StickyCard = styled(Card)(({theme}) => ({
	position: "sticky",
	top: theme.spacing(2),
}));

export function LocationEditForm(props: LocationEditFormProps) {
	const {loading, location, onSave} = props;

	const {t} = useLocalization();

	const [name, setName] = useState<string>("");
	const [type, setType] = useState<LocationType>("ROOM");
	const [parent, setParent] = useState<Location | null>(null);
	const [enabled, setEnabled] = useState<boolean>(true);

	useEffect(() => {
		setName(location?.name ?? "");
		setType(location?.type ?? "ROOM");
		setEnabled(location?.enabled ?? true);
		setParent(location?.parent ?? null);
	}, [location]);

	async function handleSave() {
		await onSave({name, type, enabled, parentId: parent?.id});
	}

	return (
		<EditForm loading={loading} onSave={handleSave} labels={t} CardProps={{component: StickyCard}}>
			<CardHeader title={t("locations.edit.title")} />
			<CardContent>
				<Stack spacing={2}>
					<TextField
						label={t("locations.name")}
						value={name}
						onChange={setName}
						fullWidth
						required
					/>
					<LocationTypeSelect value={type} onChange={setType} />

					<LocationSelect
						value={parent}
						label={t("locations.edit.changeParent")}
						onChange={setParent}
						disabledParentId={location?.id}
					/>

					<Checkbox
						label={t("locations.enabled")}
						value={enabled}
						onChange={setEnabled}
					/>
				</Stack>
			</CardContent>
		</EditForm>
	);
}
