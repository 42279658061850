import {CardContent, Grid, MenuItem} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Checkbox, EditForm, TextField} from "@variocube/app-ui";
import React, {useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {Location, useCompartmentApi, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useSearchParam} from "../../utils/search";
import {LocationSelect} from "../location/LocationSelect";
import {View, ViewHeader} from "../View";

export function CompartmentCreate() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const [locationParam] = useSearchParam("location", "");

	const [location, setLocation] = useState<Location | null>(null);
	const [number, setNumber] = useState("");
	const [size, setSize] = useState("");
	const [enabled, setEnabled] = useState<boolean>(true);
	const [lock, setLock] = useState("");
	const [sizes, setSizes] = useState<string[]>([]);

	const {createCompartment} = useCompartmentApi();
	const {getLocation, getLocationCompartmentTemplate} = useLocationApi();

	const loadLocation = useAsync(async (locationId?: string) => {
		if (locationId) {
			setLocation(await getLocation(Number(locationId)));
		}
	}, [locationParam]);

	const loadTemplate = useAsync(async (location: Location | null) => {
		if (location) {
			const {number, sizes, defaultSize} = await getLocationCompartmentTemplate(location.id);
			setNumber(number);
			setSizes(sizes);
			setSize(defaultSize ?? "");
		}
	}, [location]);

	async function handleSave() {
		const createdCompartment = await createCompartment({
			number,
			locationId: location!.id,
			size,
			enabled,
			lock: lock.replaceAll(/[^a-fA-F0-9]/g, "").toUpperCase(),
		});
		console.log("Created", createdCompartment);
		navigate(`../${createdCompartment.id}`);
	}

	return (
		<View error={loadLocation.error || loadTemplate.error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."} relative="path">{t("compartments.title")}</BreadcrumbLink>
				<BreadcrumbItem>{t("compartments.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("compartments.create.title")} />
			<EditForm loading={loadLocation.loading} onSave={handleSave} labels={t}>
				<CardContent>
					<LocationSelect value={location} onChange={setLocation} required fullWidth />
				</CardContent>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								label={t("compartments.number")}
								value={number}
								onChange={setNumber}
								fullWidth
								required
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								label={t("compartments.size")}
								value={size}
								onChange={setSize}
								fullWidth
								select
							>
								{sizes.map(size => <MenuItem key={size} value={size}>{size}</MenuItem>)}
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Checkbox
								label={t("compartments.enabled")}
								value={enabled}
								onChange={setEnabled}
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={4}>
							<TextField
								label={t("compartments.lock.title")}
								value={lock}
								onChange={setLock}
								fullWidth
								inputProps={{
									pattern: "[a-fA-F0-9: ]*",
								}}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</EditForm>
		</View>
	);
}
