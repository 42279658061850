import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {Feature, Permission, PERMISSIONS, Plan, Tenant, TenantSettings, UserPermissions, useTenantApi} from "./api";
import {LoginState} from "./LoginState";

interface TenantContextContent {
	tenant?: Tenant;
	settings?: TenantSettings;
	permissions: UserPermissions[];
	reload?: () => any;
	setPermissions: (permissions: UserPermissions[]) => void;
	plan?: Plan;
}

const TenantContext = createContext<TenantContextContent>({
	permissions: [],
	setPermissions: () => void 0,
});

interface TenantProviderProps {
	tenantId: string;
}

export function TenantProvider({tenantId, children}: PropsWithChildren<TenantProviderProps>) {
	const {getTenant, getTenantSettings, getTenantPermissions, getTenantPlan} = useTenantApi();

	const [tenant, setTenant] = useState<Tenant>();
	const [settings, setSettings] = useState<TenantSettings>();
	const [permissions, setPermissions] = useState<UserPermissions[]>([]);
	const [plan, setPlan] = useState<Plan>();

	const {error, result, execute} = useAsync(
		(tenantId: string) =>
			Promise.all([
				getTenant(tenantId),
				getTenantSettings(tenantId),
				getTenantPermissions(tenantId),
				getTenantPlan(tenantId),
			]),
		[tenantId],
	);

	useEffect(() => {
		if (result) {
			const [tenant, settings, permissions, plan] = result;
			setTenant(tenant);
			setSettings(settings);
			setPermissions(permissions);
			setPlan(plan);
		}
	}, [result]);

	const reload = useCallback(() => execute(tenantId), [execute, tenantId]);

	const value = useMemo(() => ({
		tenant,
		settings,
		reload,
		permissions,
		setPermissions,
		plan,
	}), [tenant, settings, reload, permissions, setPermissions]);

	return (
		<LoginState loading={!tenant} error={error} tenantId={tenantId}>
			<TenantContext.Provider value={value}>
				{children}
			</TenantContext.Provider>
		</LoginState>
	);
}

export function useTenantId() {
	const {tenant} = useContext(TenantContext);
	if (!tenant) {
		throw new Error("No tenant found in context. You are probably calling this from outside a TenantProvider.");
	}
	return tenant.id;
}

export function useTenantSettings() {
	const {settings} = useContext(TenantContext);
	if (!settings) {
		throw new Error("No tenant found in context. You are probably calling this from outside a TenantProvider.");
	}
	return settings;
}

export function useReloadTenant() {
	const {reload} = useContext(TenantContext);
	if (!reload) {
		throw new Error("No tenant found in context. You are probably calling this from outside a TenantProvider.");
	}
	return reload;
}

export function usePermissions() {
	const {permissions} = useContext(TenantContext);
	return permissions;
}

export function useEmulatePermissions() {
	const {permissions, setPermissions} = useContext(TenantContext);
	const setEmulatedPermissions = useCallback((permissions: Permission[]) => {
		setPermissions([{
			location: undefined,
			permissions,
		}]);
	}, [setPermissions]);
	const emulatedPermissions = permissions.find(p => !p.location)?.permissions ?? PERMISSIONS;

	return [emulatedPermissions, setEmulatedPermissions] as const;
}

export function useTenantPermission(permission: Permission) {
	const permissions = usePermissions();
	return hasTenantPermission(permissions, permission);
}

export function useTenantPermissions(...requestedPermissions: Permission[]) {
	const permissions = usePermissions();
	return requestedPermissions.every(permission => hasTenantPermission(permissions, permission));
}

export function useTenantPermissionsFlat() {
	const permissions = usePermissions();

	const flattenedTenantPermissions = permissions
		.filter(role => !role.location)
		.flatMap(role => role.permissions ?? []);

	return Array.from(
		new Set(flattenedTenantPermissions),
	);
}

function hasTenantPermission(permissions: UserPermissions[], permission: Permission) {
	return Boolean(permissions.find(role => !role.location && role.permissions?.includes(permission)));
}

export function useLocationPermission(permission: Permission, locationId?: number) {
	const permissions = usePermissions();
	return Boolean(
		permissions.find(role =>
			(!role.location || role.location.id == locationId) && role.permissions?.includes(permission)
		),
	);
}

export function useAnyPermission(...requestedPermissions: Permission[]) {
	const permissions = usePermissions();
	return requestedPermissions.some(permission => (
		Boolean(permissions.find(role => role.permissions?.includes(permission)))
	));
}

export function useTenantFeature(feature: Feature) {
	const {plan} = useContext(TenantContext);
	return plan?.features.includes(feature);
}
