import {AddLocation, Delete} from "@mui/icons-material";
import {Box, Button, CardActions, Divider, Paper, Stack, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {useQuery} from "@tanstack/react-query";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, ConfirmDialog, useFlag} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {LocationMutation, LocationTree, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {queryKeys} from "../../utils/queryKeys";
import {View, ViewHeader} from "../View";
import {LocationEditForm} from "./LocationEditForm";
import {LocationTreeView} from "./LocationTreeView";

export function LocationManagement() {
	const {t} = useLocalization();
	const [selectedTree, setSelectedTree] = useState<LocationTree>();

	const [deleteOpen, setDeleteOpen, clearDeleteOpen] = useFlag(false);
	const [expandLocationId, setExpandLocationId] = useState<number>();
	const tenantId = useTenantId();

	const {createLocation, deleteLocation, getLocation, updateLocation, getLocationTrees} = useLocationApi();

	const {isLoading: treeLoading, isError: treeError, data: locationsResult, refetch} = useQuery(
		queryKeys.locationTrees(tenantId),
		getLocationTrees,
	);

	// remember last loaded locations
	const [locations, setLocations] = useState<LocationTree[]>();
	useEffect(() => {
		if (locationsResult) {
			setLocations(locationsResult);
			if (locationsResult.length > 0 && !selectedTree) {
				setSelectedTree(locationsResult[0]);
			}
		}
	}, [locationsResult]);

	const {
		loading: locationLoading,
		error: locationError,
		result: selectedLocation,
	} = useAsync(
		async (selectedTree: LocationTree | undefined) => {
			if (selectedTree) {
				return await getLocation(selectedTree.id);
			}
		},
		[selectedTree],
	);

	async function handleDelete() {
		if (selectedTree) {
			await deleteLocation(selectedTree.id);
			setSelectedTree(undefined);
			await refetch();
		}
		clearDeleteOpen();
	}

	async function handleSave(mutation: LocationMutation) {
		if (selectedLocation) {
			await updateLocation(selectedLocation.id, mutation);
			const locations = await refetch();
			setSelectedTree(findLocationTree(locations.data ?? [], selectedLocation.id));
		}
	}

	async function handleCreate() {
		const location = await createLocation({
			parentId: selectedLocation?.id,
			name: t("locations.create.name"),
			enabled: true,
		});
		const locations = await refetch();
		setExpandLocationId(selectedLocation?.id);
		setSelectedTree(findLocationTree(locations.data ?? [], location.id));
	}

	return (
		<View error={treeError || locationError}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."}>{t("locations.title")}</BreadcrumbLink>
				<BreadcrumbItem>{t("locations.manage")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("locations.manage")} />
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6}>
						<Paper>
							<CardActions sx={{justifyContent: "space-between"}}>
								<Button color="primary" startIcon={<AddLocation />} onClick={handleCreate}>
									{t("locations.create.title")}
								</Button>
								<Button
									color="error"
									startIcon={<Delete />}
									onClick={setDeleteOpen}
									disabled={!selectedTree || selectedTree.children.length > 0}
								>
									{t("locations.delete.title")}
								</Button>
							</CardActions>
							<Divider />
							<LocationTreeView
								selected={selectedTree}
								onSelect={setSelectedTree}
								locations={locations ?? []}
								loading={treeLoading}
								expandLocationId={expandLocationId}
							/>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						{selectedTree && (
							<LocationEditForm
								location={selectedLocation}
								onSave={handleSave}
								loading={locationLoading}
							/>
						)}
					</Grid>
				</Grid>
			</Box>

			<ConfirmDialog
				title={t("locations.delete.title")}
				cancel={t("cancel")}
				onConfirm={handleDelete}
				open={deleteOpen}
				onClose={clearDeleteOpen}
				color="error"
			>
				<Stack spacing={2}>
					<Typography paragraph>{t("locations.delete.message")}</Typography>
					<Typography paragraph>{t("locations.delete.type")}</Typography>
					<TextField
						label={t("locations.name")}
						inputProps={{pattern: selectedTree?.name}}
						required
						fullWidth
					/>
					<Typography paragraph>{t("locations.delete.confirm", selectedTree)}</Typography>
				</Stack>
			</ConfirmDialog>
		</View>
	);
}

function findLocationTree(locations: LocationTree[], id: number): LocationTree | undefined {
	for (const location of locations) {
		if (location.id == id) {
			return location;
		}
		const child = findLocationTree(location.children, id);
		if (child) {
			return child;
		}
	}
}
