import {Tabs} from "@variocube/app-ui";
import React from "react";
import {useLocalization} from "../../i18n";
import {useTenantFeature} from "../../tenant";
import {useSearchParam} from "../../utils/search";
import {View, ViewHeader} from "../View";
import BookingMetrics from "./metrics/BookingMetrics";
import CompartmentMetrics from "./metrics/CompartmentMetrics";
import Utilization from "./utilization/Utilization";

type TabType = "utilization" | "bookingMetrics" | "compartmentMetrics";

export default function StatisticsView() {
	const {t} = useLocalization();
	const showDetailedStatistics = useTenantFeature("DETAILED_STATISTICS");
	const [selectedTab, setSelectedTab] = useSearchParam("tab", "utilization");

	let tabs = [
		{label: t("statistics.utilization"), value: "utilization"},
	];

	if (showDetailedStatistics) {
		tabs.push({label: t("statistics.bookingMetrics.title"), value: "bookingMetrics"});
		tabs.push({label: t("statistics.compartmentMetrics.title"), value: "compartmentMetrics"});
	}

	return (
		<View>
			<ViewHeader
				title={t("statistics.title")}
			/>

			<Tabs
				value={selectedTab}
				onChange={(_, tab: TabType) => setSelectedTab(tab)}
				items={tabs}
			/>

			{selectedTab === "utilization" && <Utilization />}
			{selectedTab === "bookingMetrics" && <BookingMetrics />}
			{selectedTab === "compartmentMetrics" && <CompartmentMetrics />}
		</View>
	);
}
