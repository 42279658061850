import {Chip} from "@mui/material";
import {Filter, SpringPageable} from "@variocube/app-ui";
import React from "react";
import {PlanQuery} from "../../api/plan";
import {useLocalization} from "../../i18n";

export type PlanFilter = Omit<PlanQuery, keyof SpringPageable>;

interface PlanFilterProps {
	value: PlanFilter;
	onSearch: (value?: string) => any;
}

export function PlanFilter({value, onSearch}: PlanFilterProps) {
	const {t, s} = useLocalization();

	return (
		<Filter
			label={t("filter.title")}
			enableSearch
			onSearch={onSearch}
			active={[
				value.search && (
					<Chip
						key={"search"}
						label={`${t("filter.search")}: ${value.search}`}
						onDelete={() => onSearch(undefined)}
					/>
				),
			]}
			labels={s("filter")}
		/>
	);
}
