import {CardContent, CardHeader, Stack} from "@mui/material";
import {EditForm, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {Booking, BookingNameMutation} from "../../api";
import {useLocalization} from "../../i18n";

interface BookingNameEditFormProps {
	loading: boolean;
	booking?: Booking;
	onSave: (mutation: BookingNameMutation) => Promise<any>;
}

export function BookingNameEditForm(props: BookingNameEditFormProps) {
	const {loading, booking, onSave} = props;
	const {t} = useLocalization();

	const [name, setName] = useState<string>("");

	useEffect(() => {
		if (booking) {
			setName(booking.name ?? "");
		}
	}, [booking]);

	async function handleSave() {
		await onSave({name});
	}

	return (
		<EditForm loading={loading} onSave={handleSave} labels={t}>
			<CardHeader title={t("bookings.edit.name")} />
			<CardContent>
				<Stack spacing={2}>
					<TextField
						label={t("bookings.edit.name")}
						value={name}
						onChange={setName}
						fullWidth
					/>
				</Stack>
			</CardContent>
		</EditForm>
	);
}
