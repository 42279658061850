import {CircularProgress, Stack, TabProps} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Tabs} from "@variocube/app-ui";
import React, {useEffect, useMemo, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useSearchParams} from "react-router-dom";
import {Location, useLocationApi} from "../../api";
import {DisabledChip} from "../../controls/DisabledChip";
import {useLocalization} from "../../i18n";
import {useLocationPermission, useTenantPermission} from "../../tenant";
import {useNumericParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {LocationAuditTimeline} from "./LocationAuditTimeline";
import {LocationChildrenList} from "./LocationChildrenList";
import {LocationLinkChip, LocationTypeChip} from "./LocationChip";
import {LocationCompartmentList} from "./LocationCompartmentList";
import {LocationSettings} from "./LocationSettings";

export function LocationView() {
	const {t} = useLocalization();
	const locationId = useNumericParam("locationId");

	const [params, setParams] = useSearchParams();
	const [tabSelected, setTabSelected] = useState<string | null>(params.get("tab"));

	const {getLocation} = useLocationApi();
	const {loading, error, result: location} = useAsync(getLocation, [locationId]);

	const canEditSettings = useLocationPermission("SETTINGS_EDIT", locationId);
	const canReadAudit = useTenantPermission("AUDIT_LOG_READ");

	const tabs = useTabs(location, canReadAudit, canEditSettings);

	useEffect(() => {
		if (!tabSelected && tabs.length > 0 && locationId === location?.id) {
			setTabSelected(tabs[0].value);
		}
	}, [tabSelected, tabs, location, locationId]);

	useEffect(() => {
		if (locationId !== location?.id) setTabSelected(null);
	}, [locationId, location]);

	if (loading) {
		return (
			<Stack sx={{height: "100%", justifyContent: "center", alignItems: "center"}}>
				<CircularProgress />
			</Stack>
		);
	}

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to="..">
					{t("locations.title")}
				</BreadcrumbLink>
				<BreadcrumbItem>{location?.name}</BreadcrumbItem>
			</Breadcrumbs>

			<ViewHeader
				title={location?.name || ""}
				subTitle={location?.parent && <LocationLinkChip location={location.parent} />}
				titleAdornment={
					<Stack direction="row" spacing={1} alignItems="center">
						{!location?.enabled && <DisabledChip />}
						{location?.type && <LocationTypeChip locationType={location.type} />}
					</Stack>
				}
			/>

			{tabSelected && tabs.length > 0 && (
				<>
					<Tabs
						value={tabSelected}
						onChange={(_, newTab) => {
							setParams({tab: newTab});
							setTabSelected(newTab);
						}}
						items={tabs}
					/>

					{tabSelected === "locations" && <LocationChildrenList />}
					{tabSelected === "compartments" && <LocationCompartmentList />}
					{tabSelected === "audit" && <LocationAuditTimeline />}
					{tabSelected === "settings" && canEditSettings && <LocationSettings />}
				</>
			)}
		</View>
	);
}

function useTabs(
	location: Location | undefined,
	canReadAudit: boolean,
	canEditSettings: boolean,
): TabProps[] {
	const {t} = useLocalization();

	return useMemo(() => {
		const tabs: TabProps[] = [];

		if (location?.hasChildren) {
			tabs.push({label: t("locations.title"), value: "locations"});
		}

		if (location?.hasCompartments) {
			tabs.push({label: t("compartments.title"), value: "compartments"});
		}

		if (canReadAudit) {
			tabs.push({label: t("audit.title"), value: "audit"});
		}

		if (canEditSettings) {
			tabs.push({label: t("settings.title"), value: "settings"});
		}

		return tabs;
	}, [location, canReadAudit, canEditSettings]);
}
