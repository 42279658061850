import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, EditForm} from "@variocube/app-ui";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Feature} from "../../api";
import {usePlanApi} from "../../api/plan";
import {useLocalization} from "../../i18n";
import {View, ViewHeader} from "../View";
import {PlanForm} from "./PlanForm";

export function PlanCreate() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const {createPlan} = usePlanApi();

	const [name, setName] = useState<string>("");
	const [selectedFeatures, setSelectedFeatures] = React.useState<Feature[]>([]);

	const handleSave = async () => {
		const createdPlan = await createPlan({
			name,
			features: selectedFeatures,
		});
		navigate(`../${createdPlan.id}`);
	};

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."}>
					{t("plans.title")}
				</BreadcrumbLink>
				<BreadcrumbItem>{t("plans.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("plans.create.title")} />
			<EditForm onSave={handleSave} labels={t} loading={false}>
				<PlanForm
					name={name}
					setName={setName}
					selectedFeatures={selectedFeatures}
					setSelectedFeatures={setSelectedFeatures}
					labelTexts={{
						nameLabel: t("plans.create.name"),
						featuresLabel: t("plans.create.features"),
					}}
				/>
			</EditForm>
		</View>
	);
}
