import React from "react";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function MetricsAccordion({
	expanded,
	onToggle,
	title,
	children,
}: React.PropsWithChildren<{
	expanded: boolean;
	onToggle: () => void;
	title: string;
}>) {
	return (
		<Accordion expanded={expanded} onChange={onToggle}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography>{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>{expanded && <>{children}</>}</AccordionDetails>
		</Accordion>
	);
}
