import {useMemo} from "react";
import {TypeOf} from "zod";
import {useAccessToken} from "../auth";
import {
	BookingSettings,
	CompartmentSettings,
	createApiClient,
	get_QueryTenantAuditLog,
	SupportSettings,
	TenantAuditSettings,
	TenantCreation,
	TenantMutation,
} from "./client";
import {get_QueryTenants} from "./client.gen";

export function useTenantApi() {
	const accessToken = useAccessToken();
	return useMemo(() => createTenantApi(accessToken), [accessToken]);
}

export type TenantQuery = TypeOf<get_QueryTenants["parameters"]>["query"];
export type AuditLogQuery = TypeOf<get_QueryTenantAuditLog["parameters"]>["query"];

function createTenantApi(accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		getTenant: (tenantId: string) =>
			api.get("/api/tenants/{tenantId}", {
				path: {tenantId},
			}),
		getTenantSettings: (tenantId: string) =>
			api.get("/api/tenants/{tenantId}/settings", {
				path: {tenantId},
			}),
		getTenantAuditSettings: (tenantId: string) =>
			api.get("/api/tenants/{tenantId}/settings/audit", {
				path: {tenantId},
			}),
		getTenantBookingSettings: (tenantId: string) =>
			api.get("/api/tenants/{tenantId}/settings/booking", {
				path: {tenantId},
			}),
		getTenantCompartmentSettings: (tenantId: string) =>
			api.get("/api/tenants/{tenantId}/settings/compartment", {
				path: {tenantId},
			}),
		getTenantSupportSettings: (tenantId: string) =>
			api.get("/api/tenants/{tenantId}/settings/support", {
				path: {tenantId},
			}),
		createTenant: (creation: TenantCreation) =>
			api.post("/api/tenants", {
				body: creation,
			}),
		updateTenant: (tenantId: string, mutation: TenantMutation) =>
			api.put("/api/tenants/{tenantId}", {
				path: {tenantId},
				body: mutation,
			}),
		updateTenantBookingSettings: (tenantId: string, mutation: BookingSettings) =>
			api.put("/api/tenants/{tenantId}/settings/booking", {
				path: {tenantId},
				body: mutation,
			}),
		updateTenantAuditSettings: (tenantId: string, mutation: TenantAuditSettings) =>
			api.put("/api/tenants/{tenantId}/settings/audit", {
				path: {tenantId},
				body: mutation,
			}),
		updateTenantCompartmentSettings: (tenantId: string, mutation: CompartmentSettings) =>
			api.put("/api/tenants/{tenantId}/settings/compartment", {
				path: {tenantId},
				body: mutation,
			}),
		updateTenantSupportSettings: (tenantId: string, mutation: SupportSettings) =>
			api.put("/api/tenants/{tenantId}/settings/support", {
				path: {tenantId},
				body: mutation,
			}),
		deleteTenant: (tenantId: string) =>
			api.delete("/api/tenants/{tenantId}", {
				path: {tenantId},
			}),
		queryTenants: (query: TenantQuery) =>
			api.get("/api/tenants", {
				query,
			}),
		queryTenantAuditLog: (tenantId: string, query: AuditLogQuery) =>
			api.get("/api/tenants/{tenantId}/audit", {
				path: {tenantId},
				query,
			}),
		getTenantPermissions: (tenantId: string) =>
			api.get("/api/tenants/{tenantId}/permissions", {
				path: {tenantId},
			}),
		getTenantPlan: (tenantId: string) =>
			api.get("/api/tenants/{tenantId}/plan", {
				path: {tenantId},
			}),
	};
}
