import {DataTable} from "@variocube/app-ui";
import {DataTablePage} from "@variocube/app-ui/esm/data-table/DataTable";
import React, {useMemo} from "react";
import {EmptyList} from "../../controls";
import {useLocalization} from "../../i18n";
import {BookingCell} from "./BookingCell";
import {BookingCompartmentCell} from "./BookingCompartmentCell";
import {BookingFromCell} from "./BookingFromCell";
import {BookingLocationCell} from "./BookingLocationCell";
import {BookingUntilCell} from "./BookingUntilCell";
import {BookingUsersCell} from "./BookingUsersCell";

interface BookingTableProps {
	loading: boolean;
	error: any;
	rows: any[];
	page: any;
	onSort: (field: string) => void;
	onPageChange: ({pageSize, pageIndex}: DataTablePage) => void;
	storage: any;
}

export default function BookingTable({
	loading,
	error,
	rows,
	page,
	onSort,
	onPageChange,
	storage,
}: Readonly<BookingTableProps>) {
	const {t} = useLocalization();

	const columns = useMemo(() => [
		{
			label: t("bookings.single"),
			field: "id",
			sortable: true,
			default: true,
			component: BookingCell,
		},
		{
			label: t("bookings.location"),
			field: "compartment.location.name",
			sortable: false,
			default: true,
			component: BookingLocationCell,
		},
		{
			label: t("bookings.compartment"),
			field: "compartment.number",
			sortable: false,
			default: true,
			component: BookingCompartmentCell,
		},
		{
			label: t("bookings.users.title"),
			field: "users.username",
			sortable: true,
			default: true,
			component: BookingUsersCell,
		},
		{
			label: t("bookings.from"),
			field: "from",
			sortable: true,
			default: true,
			component: BookingFromCell,
		},
		{
			label: t("bookings.until"),
			field: "until",
			sortable: true,
			default: true,
			component: BookingUntilCell,
		},
	], [t]);

	return (
		<DataTable
			columns={columns}
			rows={rows}
			page={page}
			loading={loading}
			error={error}
			onSort={onSort}
			onPageChange={onPageChange}
			empty={<EmptyList />}
			{...storage}
		/>
	);
}
