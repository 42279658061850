import {SpringPageable} from "@variocube/app-ui";

export const queryKeys = {
	locationTrees: (tenantId: string) => ["locationTrees", tenantId],
	utilizationStatistics: (
		tenantId: string,
		locationId: number | undefined | null,
		from: string | undefined | null,
		until: string | undefined | null,
	) => ["utilizationStatistics", tenantId, locationId, from, until],
	bookingsWhichHaveBeenClosedButNotOpenedList: (
		tenantId: string,
		from: string | undefined | null,
		until: string | undefined | null,
		pageable: SpringPageable,
	) => ["bookingsWhichHaveBeenClosedButNotOpenedList", tenantId, from, until, pageable],
	bookingsWhichHaveBeenNeitherOpenedNorClosedList: (
		tenantId: string,
		from: string | undefined | null,
		until: string | undefined | null,
		pageable: SpringPageable,
	) => ["bookingsWhichHaveBeenNeitherOpenedNorClosedList", tenantId, from, until, pageable],
	compartmentsWithoutUsage: (
		tenantId: string,
		from: string | undefined | null,
		until: string | undefined | null,
		pageable: SpringPageable,
	) => ["compartmentsWithoutUsage", tenantId, from, until, pageable],
};
