import {CardContent, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormLabel} from "@mui/material";
import {TextField} from "@variocube/app-ui";
import React from "react";
import {Feature} from "../../api";
import {FeatureEnumValues} from "../../api/plan";
import {useLocalization} from "../../i18n";

export interface PlanFormProps {
	name: string;
	setName: (value: string) => void;
	selectedFeatures: Feature[];
	setSelectedFeatures: React.Dispatch<React.SetStateAction<Feature[]>>;
	labelTexts: {
		nameLabel: string;
		featuresLabel: string;
	};
}

export const PlanForm: React.FC<PlanFormProps> = ({
	name,
	setName,
	selectedFeatures,
	setSelectedFeatures,
	labelTexts,
}) => {
	const {t} = useLocalization();

	const handleFeatureChange = (feature: Feature) => {
		setSelectedFeatures((prevState) =>
			prevState.includes(feature)
				? prevState.filter((f) => f !== feature)
				: [...prevState, feature]
		);
	};

	return (
		<>
			<CardContent>
				<TextField
					label={labelTexts.nameLabel}
					value={name}
					onChange={setName}
					fullWidth
					required
				/>
			</CardContent>
			<CardContent>
				<FormControl sx={{m: 1}} component="fieldset" variant="standard">
					<FormLabel component="legend">{labelTexts.featuresLabel}</FormLabel>
					<FormGroup>
						{FeatureEnumValues.map((feature) => (
							<FormControlLabel
								key={feature}
								control={
									<Checkbox
										checked={selectedFeatures.includes(feature)}
										onChange={() => handleFeatureChange(feature)}
									/>
								}
								label={
									<>
										<Chip label={feature} /> - {t(`plans.features.${feature}`)}
									</>
								}
							/>
						))}
					</FormGroup>
				</FormControl>
			</CardContent>
		</>
	);
};
