import {DataTable, DataTableCellProps} from "@variocube/app-ui";
import {DataTablePage} from "@variocube/app-ui/esm/data-table/DataTable";
import React, {useMemo} from "react";
import {Compartment} from "../../api";
import {EmptyList} from "../../controls";
import {useLocalization} from "../../i18n";
import {LocationLinkChip} from "../location/LocationChip";
import {CompartmentCell} from "./CompartmentCell";
import {CompartmentStatusChip} from "./CompartmentStatusChip";
import {LockStatusChip} from "./LockStatusChip";

interface CompartmentTableProps {
	loading: boolean;
	error: any;
	rows: Compartment[];
	page: any;
	onSort: (field: string) => void;
	onPageChange: ({pageSize, pageIndex}: DataTablePage) => void;
	storage: any;
}

export default function CompartmentTable({
	loading,
	error,
	rows,
	page,
	onSort,
	onPageChange,
	storage,
}: Readonly<CompartmentTableProps>) {
	const {t} = useLocalization();

	const columns = useMemo(() => [
		{
			label: t("compartments.number"),
			field: "number",
			sortable: true,
			default: true,
			component: CompartmentCell,
		},
		{
			label: t("compartments.size"),
			field: "size",
			sortable: true,
			default: true,
		},
		{
			label: t("compartments.location.title"),
			field: "location.name",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) => <LocationLinkChip location={row.location} />,
		},
		{
			label: t("compartments.status.title"),
			field: "booked",
			sortable: false,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) => <CompartmentStatusChip booked={row.booked} />,
		},
		{
			label: t("compartments.lock.status"),
			field: "lock.status",
			sortable: false,
			default: true,
			component: ({row}: DataTableCellProps<Compartment>) =>
				row.lock?.status ? <LockStatusChip status={row.lock.status} /> : null,
		},
	], [t]);

	return (
		<DataTable
			columns={columns}
			rows={rows}
			page={page}
			loading={loading}
			error={error}
			onSort={onSort}
			onPageChange={onPageChange}
			empty={<EmptyList />}
			{...storage}
		/>
	);
}
