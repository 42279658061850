import {Box} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {TimeFrameType, useDataTableStorage, useSpringPage, useSpringPageable, useStorage} from "@variocube/app-ui";
import React from "react";
import {useBookingStatisticsApi} from "../../../api/statistics";
import {useLocalization} from "../../../i18n";
import {useTenantFeature, useTenantId} from "../../../tenant";
import {queryKeys} from "../../../utils/queryKeys";
import BookingTable from "../../booking/BookingTable";
import {BookingMetricsFilter} from "./BookingMetricsFilter";
import {MetricsAccordion} from "./MetricsAccordion";

export default function BookingMetrics() {
	const showDetailedStatistics = useTenantFeature("DETAILED_STATISTICS");

	const {t} = useLocalization();

	const [filter, setFilter] = useStorage<BookingMetricsFilter>("BookingMetrics.filter", {
		timeFrame: {type: TimeFrameType.All},
	});

	const [accordionsState, setAccordionsState] = useStorage<Record<string, boolean>>(
		"BookingMetrics.accordionsState",
		{
			closedBookingsExpanded: false,
			neverOpenedOrClosedExpanded: false,
		},
	);

	const handleAccordionToggle = (key: keyof typeof accordionsState) => {
		setAccordionsState({...accordionsState, [key]: !accordionsState[key]});
	};

	if (!showDetailedStatistics) {
		return null;
	}

	return (
		<>
			<BookingMetricsFilter filter={filter} onChange={setFilter} />
			<Box>
				<MetricsAccordion
					expanded={accordionsState.closedBookingsExpanded}
					onToggle={() => handleAccordionToggle("closedBookingsExpanded")}
					title={t("statistics.bookingMetrics.closedBookings")}
				>
					<BookingsWhichHaveBeenClosedButNotOpenedList filter={filter} />
				</MetricsAccordion>

				<MetricsAccordion
					expanded={accordionsState.neverOpenedOrClosedExpanded}
					onToggle={() => handleAccordionToggle("neverOpenedOrClosedExpanded")}
					title={t("statistics.bookingMetrics.neverOpenedOrClosed")}
				>
					<BookingsWhichHaveBeenNeitherOpenedNorClosedList filter={filter} />
				</MetricsAccordion>
			</Box>
		</>
	);
}

function BookingsWhichHaveBeenClosedButNotOpenedList({filter}: Readonly<{ filter: BookingMetricsFilter }>) {
	const tenantId = useTenantId();
	const {onPageChange, onSort, ...storage} = useDataTableStorage(
		"BookingsWhichHaveBeenClosedButNotOpenedList.paging",
	);
	const pageable = useSpringPageable(storage);

	const {findBookingsWhichHaveBeenClosedButNotOpened} = useBookingStatisticsApi();
	const {data, isLoading, error} = useQuery(
		queryKeys.bookingsWhichHaveBeenClosedButNotOpenedList(
			tenantId,
			filter.timeFrame.from,
			filter.timeFrame.until,
			pageable,
		),
		() => findBookingsWhichHaveBeenClosedButNotOpened(filter.timeFrame.from, filter.timeFrame.until, pageable),
		{
			staleTime: 60_000,
		},
	);
	const {rows, page} = useSpringPage(data);

	return (
		<BookingTable
			loading={isLoading}
			error={error}
			rows={rows}
			page={page}
			onSort={onSort}
			onPageChange={onPageChange}
			storage={storage}
		/>
	);
}

function BookingsWhichHaveBeenNeitherOpenedNorClosedList({filter}: Readonly<{ filter: BookingMetricsFilter }>) {
	const tenantId = useTenantId();
	const {onPageChange, onSort, ...storage} = useDataTableStorage(
		"BookingsWhichHaveBeenNeitherOpenedNorClosedList.paging",
	);
	const pageable = useSpringPageable(storage);

	const {findBookingsWhichHaveBeenNeitherOpenedNorClosed} = useBookingStatisticsApi();
	const {data, isLoading, error} = useQuery(
		queryKeys.bookingsWhichHaveBeenNeitherOpenedNorClosedList(
			tenantId,
			filter.timeFrame.from,
			filter.timeFrame.until,
			pageable,
		),
		() => findBookingsWhichHaveBeenNeitherOpenedNorClosed(filter.timeFrame.from, filter.timeFrame.until, pageable),
		{
			staleTime: 60_000,
		},
	);
	const {rows, page} = useSpringPage(data);

	return (
		<BookingTable
			loading={isLoading}
			error={error}
			rows={rows}
			page={page}
			onSort={onSort}
			onPageChange={onPageChange}
			storage={storage}
		/>
	);
}
