import {memo, useMemo} from "react";
import {TypeOf, z} from "zod";
import {useAccessToken} from "../auth";
import {createApiClient, Feature, get_QueryPlans, PlanCreation, PlanMutation} from "./client";

export function usePlanApi() {
	const accessToken = useAccessToken();
	return useMemo(() => createPlanApi(accessToken), [accessToken]);
}

export type PlanQuery = TypeOf<get_QueryPlans["parameters"]>["query"];

export const FeatureEnumValues: z.infer<typeof Feature>[] = Feature._def.options.map((feature) => feature._def.value);

function createPlanApi(accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		getPlan: (planId: number) =>
			api.get("/api/plans/{planId}", {
				path: {planId},
			}),
		queryPlans: (query: PlanQuery) =>
			api.get("/api/plans", {
				query,
			}),
		createPlan: (planCreation: PlanCreation) =>
			api.post("/api/plans", {
				body: planCreation,
			}),
		updatePlan: (planId: number, planMutation: PlanMutation) =>
			api.put("/api/plans/{planId}", {
				path: {planId},
				body: planMutation,
			}),
		deletePlan: (planId: number) =>
			api.delete("/api/plans/{planId}", {
				path: {planId},
			}),
	};
}
