import React from "react";
import {Route, Routes} from "react-router-dom";
import {LockScanView} from "./LockScanView";

export function LockSearch() {
	return (
		<Routes>
			<Route index element={<LockScanView />} />
		</Routes>
	);
}
