import {CardContent, Chip, Stack} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {defined, Filter, RadioGroup, SpringPageable, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {CompartmentQuery, CompartmentStates, useCompartmentApi, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {LocationChip} from "../location/LocationChip";
import {LocationSelect} from "../location/LocationSelect";

export type CompartmentFilter = Omit<CompartmentQuery, keyof SpringPageable>;

interface CompartmentFilterProps {
	value: CompartmentFilter;
	onChange: (value: CompartmentFilter) => any;
}

export function CompartmentFilter({value, onChange}: CompartmentFilterProps) {
	const {t, s} = useLocalization();
	const tenantId = useTenantId();

	const {getLocation} = useLocationApi();
	const {getCompartmentSizes} = useCompartmentApi();

	const [lockId, setLockId] = useState<string | undefined>(value.lockId);

	useEffect(() => {
		setLockId(value.lockId);
	}, [value.lockId]);

	const location = useAsync(async (locationId?: number) => {
		if (locationId) {
			return await getLocation(locationId);
		}
	}, [value.location]);

	const sizes = useAsync(getCompartmentSizes, [tenantId]);

	return (
		<Filter
			label={t("filter.title")}
			active={[
				value.status && (
					<Chip
						key={"status"}
						label={`${t("compartments.status.title")}: ${t(`compartments.status.${value.status}`)}`}
						onDelete={() => onChange({...value, status: undefined})}
					/>
				),
				location.result && (
					<LocationChip
						key={"location"}
						location={location.result}
						onDelete={() => onChange({...value, location: undefined})}
					/>
				),
				value.compartmentSize && (
					<Chip
						key={"compartmentSize"}
						label={`${t("compartments.size")}: ${value.compartmentSize}`}
						onDelete={() => onChange({...value, compartmentSize: undefined})}
					/>
				),
				value.hasLock && (
					<Chip
						key={"hasLock"}
						label={t("compartments.lockAssignment.withLock")}
						onDelete={() => onChange({...value, hasLock: undefined})}
					/>
				),
				value.lockId && (
					<Chip
						key={"lockId"}
						label={`${t("compartments.lock.id")}: ${value.lockId}`}
						onDelete={() => onChange({...value, lockId: undefined})}
					/>
				),
				defined(value.hasLock) && !value.hasLock && (
					<Chip
						key={"withoutLock"}
						label={t("compartments.lockAssignment.withoutLock")}
						onDelete={() => onChange({...value, hasLock: undefined})}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={3}>
						<RadioGroup
							label={t("compartments.size")}
							value={value.compartmentSize || "__all"}
							onChange={compartmentSize =>
								onChange({
									...value,
									compartmentSize: compartmentSize == "__all" ? undefined : compartmentSize,
								})}
							options={["__all", ...(sizes.result ?? [])]}
							renderLabel={value => (value == "__all" ? t("compartments.sizes.all") : value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<RadioGroup
							label={t("compartments.status.title")}
							value={value.status || "all"}
							onChange={newStatus =>
								onChange({...value, status: newStatus == "all" ? undefined : newStatus})}
							options={["all", ...CompartmentStates]}
							renderLabel={s("compartments.status")}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<RadioGroup
							label={t("compartments.lockAssignment.title")}
							value={getLockAssignment(value.hasLock)}
							onChange={hasLock => onChange({...value, hasLock: getLockAssignmentBoolean(hasLock)})}
							options={["withLock", "withoutLock", "all"]}
							renderLabel={s("compartments.lockAssignment")}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<Stack spacing={2}>
							<LocationSelect
								value={location.result ?? null}
								onChange={location => onChange({...value, location: location?.id})}
								fullWidth
							/>

							<TextField
								label={t("compartments.lock.id")}
								value={lockId}
								onChange={setLockId}
								onBlur={() => onChange({...value, lockId: lockId})}
								onKeyDown={event => {
									if (event.key === "Enter") {
										onChange({...value, lockId: lockId});
									}
								}}
								fullWidth
							/>
						</Stack>
					</Grid>
				</Grid>
			</CardContent>
		</Filter>
	);
}

function getLockAssignment(hasLock: boolean | undefined) {
	if (defined(hasLock)) {
		return hasLock ? "withLock" : "withoutLock";
	}
	else {
		return "all";
	}
}

function getLockAssignmentBoolean(lockAssignment: "withLock" | "withoutLock" | "all") {
	switch (lockAssignment) {
		case "withLock":
			return true;
		case "withoutLock":
			return false;
		case "all":
			return undefined;
	}
}
