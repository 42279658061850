import {Box} from "@mui/material";
import React, {useState} from "react";
import {useLocalization} from "../../i18n";
import {View, ViewHeader} from "../View";
import {LockScanFormCard, LockScanStatus} from "./LockScanFormCard";
import LockScanInformationCard from "./LockScanInformationCard";

export function LockScanView() {
	const {t} = useLocalization();

	const [lockId, setLockId] = useState<string>("");
	const [lockStatus, setLockStatus] = useState<LockScanStatus | undefined>(undefined);

	const onUpdateLockScan = (lockId: string, lockScanStatus: LockScanStatus) => {
		setLockId(lockId);
		setLockStatus(lockScanStatus);
	};

	return (
		<>
			<View>
				<ViewHeader
					title={t("lockSearch.title")}
				/>
				<Box maxWidth="sm">
					<LockScanFormCard onUpdateLockScan={onUpdateLockScan} />
				</Box>
				{lockStatus && (
					<Box maxWidth="sm">
						<LockScanInformationCard lockId={lockId} lockStatus={lockStatus} />
					</Box>
				)}
			</View>
		</>
	);
}
