import {SpringPageable} from "@variocube/app-ui";
import {useMemo} from "react";
import {useAccessToken} from "../auth";
import {useTenantId} from "../tenant";
import {createApiClient} from "./client";

export function useBookingStatisticsApi() {
	const accessToken = useAccessToken();
	const tenantId = useTenantId();
	return useMemo(() => createBookingStatisticsApi(tenantId, accessToken), [tenantId, accessToken]);
}

function createBookingStatisticsApi(tenantId: string, accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		findBookingCountPerDay: (
			from: string | undefined | null,
			until: string | undefined | null,
			locationId: number | undefined,
		) => api.get("/api/tenants/{tenantId}/booking-statistics/get-booking-count-per-day", {
			path: {tenantId},
			query: {from: from ?? undefined, until: until ?? undefined, locationId},
		}),
		findBookingsWhichHaveBeenClosedButNotOpened: (
			from: string | undefined | null,
			until: string | undefined | null,
			pageable: SpringPageable,
		) => api.get("/api/tenants/{tenantId}/booking-statistics/get-bookings-closed-but-not-opened", {
			path: {tenantId},
			query: {from: from ?? undefined, until: until ?? undefined, ...pageable},
		}),
		findBookingsWhichHaveBeenNeitherOpenedNorClosed: (
			from: string | undefined | null,
			until: string | undefined | null,
			pageable: SpringPageable,
		) => api.get("/api/tenants/{tenantId}/booking-statistics/get-bookings-neither-closed-or-opened", {
			path: {tenantId},
			query: {from: from ?? undefined, until: until ?? undefined, ...pageable},
		}),
	};
}

export function useCompartmentStatisticsApi() {
	const accessToken = useAccessToken();
	const tenantId = useTenantId();
	return useMemo(() => createCompartmentStatisticsApi(tenantId, accessToken), [tenantId, accessToken]);
}

function createCompartmentStatisticsApi(tenantId: string, accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		findCompartmentsWithoutUsage: (
			from: string | undefined | null,
			until: string | undefined | null,
			pageable: SpringPageable,
		) => api.get("/api/tenants/{tenantId}/compartment-statistics/get-compartments-without-usage", {
			path: {tenantId},
			query: {from: from ?? undefined, until: until ?? undefined, ...pageable},
		}),
	};
}
