import DeleteIcon from "@mui/icons-material/Delete";
import {Box, Card, CardActionArea, CardContent, IconButton, Skeleton, Typography, useTheme} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {AddIcon, DateTimeFormat, TimeFrameType, useStorage} from "@variocube/app-ui";
import {getSupportedFormatLocale} from "@variocube/app-ui/esm/getSupportedFormatLocale";
import React, {useEffect, useMemo} from "react";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {LocationTree} from "../../../api";
import {useBookingStatisticsApi} from "../../../api/statistics";
import {useLocalization} from "../../../i18n";
import {useTenantFeature, useTenantId} from "../../../tenant";
import {queryKeys} from "../../../utils/queryKeys";
import {LocationSelect} from "../../location/LocationSelect";
import {UtilizationFilter} from "./UtilizationFilter";

export default function Utilization() {
	const tenantId = useTenantId();
	const [selectedLocations, setLocationIds] = useStorage<(LocationTree | null)[]>(
		`Utilization.locationIds.${tenantId}`,
		[
			null,
		],
	);
	const showDetailedStatistics = useTenantFeature("DETAILED_STATISTICS");

	const [filter, setFilter] = useStorage<UtilizationFilter>("Utilization.filter", {
		timeFrame: {type: TimeFrameType.All},
	});

	const addUtilizationGraph = () => {
		setLocationIds([...selectedLocations, null]);
	};

	const removeUtilizationGraph = (indexToRemove: number) => {
		setLocationIds(selectedLocations.filter((_, index) => index !== indexToRemove));
	};

	const onLocationIdChange = (index: number, newLocation: LocationTree | null) => {
		const updatedLocationIds = [...selectedLocations];
		updatedLocationIds[index] = newLocation;
		setLocationIds(updatedLocationIds);
	};

	useEffect(() => {
		if (!showDetailedStatistics) {
			setLocationIds(selectedLocations.length > 0 ? [selectedLocations[0]] : [null]);
		}
	}, [selectedLocations, showDetailedStatistics]);

	return (
		<>
			<UtilizationFilter filter={filter} onChange={setFilter} />
			{selectedLocations.map((_, index) => (
				<UtilizationGraph
					key={`${index}`}
					filter={filter}
					location={selectedLocations[index]}
					onLocationIdChange={(newLocation: LocationTree | null) => {
						onLocationIdChange(index, newLocation);
					}}
					showRemove={selectedLocations.length > 1}
					onRemove={() => removeUtilizationGraph(index)}
				/>
			))}
			{showDetailedStatistics && (
				<Card sx={{height: 200}}>
					<CardActionArea
						onClick={addUtilizationGraph}
						sx={{display: "flex", height: "100%"}}
					>
						<AddIcon fontSize="large" color="primary" />
						<Typography variant="h6">
							Add Graph
						</Typography>
					</CardActionArea>
				</Card>
			)}
		</>
	);
}

interface UtilizationGraphProps {
	location: LocationTree | null;
	filter: UtilizationFilter;
	onLocationIdChange: (LocationTree: LocationTree | null) => void;
	showRemove: boolean;
	onRemove: () => void;
}

function UtilizationGraph(
	{location, filter, onLocationIdChange, showRemove, onRemove}: Readonly<UtilizationGraphProps>,
) {
	const theme = useTheme();
	const tenantId = useTenantId();

	const {t, language} = useLocalization();

	const showDetailedStatistics = useTenantFeature("DETAILED_STATISTICS");

	const dateFormat = useMemo(
		() => new DateTimeFormat(language ?? getSupportedFormatLocale("dateTime")),
		[language],
	);

	const {findBookingCountPerDay} = useBookingStatisticsApi();
	const {data, isLoading} = useQuery(
		queryKeys.utilizationStatistics(tenantId, location?.id, filter.timeFrame.from, filter.timeFrame.until),
		() => findBookingCountPerDay(filter.timeFrame.from, filter.timeFrame.until, location?.id),
		{
			staleTime: 60_000,
		},
	);

	return (
		<Card>
			<CardContent>
				<Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
					<LocationSelect value={location} onChange={onLocationIdChange} />
					{showDetailedStatistics && showRemove && (
						<IconButton
							color="error"
							onClick={onRemove}
							size="small"
							aria-label="remove"
						>
							<DeleteIcon />
						</IconButton>
					)}
				</Box>

				<Box sx={{overflowX: "auto", overflowY: "hidden", mt: 1}}>
					<Box
						sx={{
							minWidth: "800px",
							height: 500,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{isLoading && <Skeleton variant="rectangular" width="100%" height="100%" />}

						{!isLoading && (!data || data.length === 0) && (
							<Typography variant="h6">
								{t("statistics.noData")}
							</Typography>
						)}

						{!isLoading && data && data.length > 0 && (
							<ResponsiveContainer width="100%" height="100%">
								<LineChart data={data}>
									<Line
										dataKey="count"
										name={t("statistics.bookingCount")}
										stroke={theme.palette.primary.main}
									/>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis
										dataKey="date"
										tickFormatter={(value: string) => dateFormat.format(new Date(value))}
									/>
									<YAxis />
									<Tooltip
										contentStyle={{
											backgroundColor: theme.palette.background.default,
										}}
										labelFormatter={(label: string) => dateFormat.format(new Date(label))}
									/>
								</LineChart>
							</ResponsiveContainer>
						)}
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
}
