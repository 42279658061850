import {DateTimeFormat, tryParsePlainDate} from "@variocube/app-ui";
import {getSupportedFormatLocale} from "@variocube/app-ui/esm/getSupportedFormatLocale";
import {useMemo} from "react";
import {useLocalization} from "../i18n";

export function useFormattedDates(fromDate?: string | null, untilDate?: string | null) {
	const {t, language} = useLocalization();

	return useMemo(() => {
		const fromParsedDate = tryParsePlainDate(fromDate);
		const untilParsedDate = tryParsePlainDate(untilDate);

		const dateTimeFormat = new DateTimeFormat(
			language ?? getSupportedFormatLocale("dateTime"),
			{
				dateStyle: "long",
			},
		);

		if (fromParsedDate && untilParsedDate) {
			if (fromDate === untilDate) {
				return `${t("timeframe.on")}: ${
					dateTimeFormat.formatRange(
						fromParsedDate,
						untilParsedDate,
					)
				}`;
			}
			else {
				return `${t("timeframe.between")}: ${
					dateTimeFormat.formatRange(
						fromParsedDate,
						untilParsedDate,
					)
				}`;
			}
		}
		else if (fromParsedDate) {
			return `${t("timeframe.from")}: ${dateTimeFormat.format(fromParsedDate)}`;
		}
		else if (untilParsedDate) {
			return `${t("timeframe.until")}: ${dateTimeFormat.format(untilParsedDate)}`;
		}
		return "";
	}, [fromDate, untilDate, language, t]);
}
