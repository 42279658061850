import {Chip} from "@mui/material";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ErrorBoundary} from "@variocube/app-ui";
import React from "react";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from "react-router-dom";
import {AuthenticationProvider, LoginHandler, LogoutHandler} from "./auth";
import {StorageLocalizationProvider} from "./i18n";
import {SmaloxAppShell} from "./shell/SmaloxAppShell";
import {Stage} from "./stage";
import {TenantProvider, useTenantPermissions} from "./tenant";
import {AuditLog} from "./tenant/audit/AuditLog";
import {Bookings} from "./tenant/booking";
import {Compartments} from "./tenant/compartment";
import {Dashboard} from "./tenant/dashboard";
import {Plans} from "./tenant/plan";
import {Groups} from "./tenant/group";
import {Locations} from "./tenant/location";
import {LockSearch} from "./tenant/lockscan";
import {Settings} from "./tenant/settings";
import {Statistics} from "./tenant/statistics";
import {Tenants} from "./tenant/Tenants";
import {Users} from "./tenant/user";
import {ThemeProvider} from "./theme";
import {UserProvider} from "./user";
import {useParam} from "./utils/useParam";

interface AppProps {
	stage: Stage;
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 1000,
		},
	},
});

export function App({stage}: AppProps) {
	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider>
				<ErrorBoundary>
					<StorageLocalizationProvider>
						<BrowserRouter>
							<AuthenticationProvider
								loginRedirectUri={`${location.origin}/auth`}
								logoutRedirectUri={`${location.origin}/logout`}
							>
								<Routes>
									<Route path="/">
										<Route path="auth" element={<LoginHandler/>}/>
										<Route path="logout" element={<LogoutHandler/>}/>
										<Route path="tenants/*" element={<Tenants/>}/>
										<Route path=":tenantId" element={<Tenant/>}>
											<Route path="locations/*" element={<Locations/>}/>
											<Route path="compartments/*" element={<Compartments/>}/>
											<Route path="bookings/*" element={<Bookings/>}/>
											<Route path="users/*" element={<Users/>}/>
											<Route path="groups/*" element={<Groups/>}/>
											<Route path="settings/*" element={<Settings/>}/>
											<Route path="audit/*" element={<AuditLog/>}/>
											<Route path="dashboard/*" element={<Dashboard/>}/>
											<Route path="locksearch/*" element={<LockSearch/>}/>
											<Route path="plans/*" element={<Plans/>}/>
											<Route path="statistics/*" element={<Statistics />} />
											<Route index element={<TenantIndex/>}/>
										</Route>
										<Route index element={<Navigate to="tenants" replace/>}/>
									</Route>
								</Routes>
							</AuthenticationProvider>
						</BrowserRouter>
					</StorageLocalizationProvider>
				</ErrorBoundary>
				<StageIndicator stage={stage}/>
			</ThemeProvider>
		</QueryClientProvider>
	);
}

export function Tenant() {
	const tenantId = useParam("tenantId");
	return (
		<UserProvider tenantId={tenantId}>
			<TenantProvider tenantId={tenantId}>
				<SmaloxAppShell>
					<Outlet />
				</SmaloxAppShell>
			</TenantProvider>
		</UserProvider>
	);
}

export function TenantIndex() {
	const dashboard = useTenantPermissions("BOOKING_READ", "TENANT_USER_READ");
	return <Navigate to={dashboard ? "dashboard" : "bookings"} />;
}

export function StageIndicator({stage}: Pick<AppProps, "stage">) {
	return stage == "production"
		? null
		: (
			<Chip
				sx={{position: "fixed", top: -4, left: "50%", transform: "translateX(-50%)"}}
				color="warning"
				size="small"
				label={stage}
			/>
		);
}
