import {Button, Card, CardContent, Stack} from "@mui/material";
import {ApiError, SearchIcon, TextField, useFormSubmit} from "@variocube/app-ui";
import React, {useState} from "react";
import {useLockScanApi} from "../../api/lockscan";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";

export type LockScanStatus = "foundInTenant" | "differentTenant" | "notFound";

interface LockScanFormCardProps {
	onUpdateLockScan: (lockId: string, lockScanStatus: LockScanStatus) => void;
}

export function LockScanFormCard({onUpdateLockScan}: LockScanFormCardProps) {
	const {t} = useLocalization();

	const {scanLock} = useLockScanApi();

	const tenantId = useTenantId();
	const [lockId, setLockId] = useState<string>("");

	const {onSubmit, onChange} = useFormSubmit({
		onSubmit: async () => {
			try {
				const result = await scanLock(lockId);
				handleLockStatus(result?.tenant?.id, tenantId);
			}
			catch (err) {
				handleLockError(err);
			}
		},
	});

	const handleLockStatus = (resultTenantId: string | undefined, currentTenantId: string) => {
		if (resultTenantId === currentTenantId) {
			onUpdateLockScan(lockId, "foundInTenant");
		}
		else if (resultTenantId) {
			onUpdateLockScan(lockId, "differentTenant");
		}
		else {
			onUpdateLockScan(lockId, "notFound");
		}
	};

	const handleLockError = (err: unknown) => {
		if (err instanceof ApiError && err.status === 404) {
			onUpdateLockScan(lockId, "notFound");
		}
	};

	return (
		<Card>
			<form onSubmit={onSubmit} onChange={onChange}>
				<CardContent>
					<TextField
						label={t("lockSearch.lockId")}
						value={lockId}
						onChange={setLockId}
						fullWidth
						required
					/>
				</CardContent>
				<CardContent>
					<Stack direction="row" spacing={2}>
						<Button
							variant="outlined"
							type="submit"
							startIcon={<SearchIcon />}
						>
							{t("lockSearch.search")}
						</Button>
					</Stack>
				</CardContent>
			</form>
		</Card>
	);
}
