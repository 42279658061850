import {Chip} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {Plan} from "../../api";
import {useTenantId} from "../../tenant";
import {PlanIcon} from "../../theme";

export interface PlanLinkChipProps {
	plan: Plan;
}

export function PlanLinkChip({plan}: PlanLinkChipProps) {
	const tenantId = useTenantId();
	return (
		<Chip
			icon={<PlanIcon />}
			label={plan.name}
			component={Link}
			to={`/${tenantId}/plans/${plan.id}`}
			clickable
			variant="outlined"
			color="secondary"
		/>
	);
}
