import {
	Apartment,
	Business,
	Domain,
	HolidayVillage,
	HouseSiding,
	ListAlt,
	LocationCity,
	Map,
	MeetingRoom,
} from "@mui/icons-material";
import React from "react";
import {LocationType} from "../../api";

export const LocationIcon = React.forwardRef<SVGSVGElement, { type: LocationType | undefined }>(
	({type, ...props}, ref) => {
		switch (type) {
			case "COMPANY":
				return <Business ref={ref} {...props} />;
			case "COUNTRY":
				return <Map ref={ref} {...props} />;
			case "CITY":
				return <LocationCity ref={ref} {...props} />;
			case "BUILDING":
				return <Apartment ref={ref} {...props} />;
			case "AREA":
				return <Domain ref={ref} {...props} />;
			case "ESTATE":
				return <HolidayVillage ref={ref} {...props} />;
			case "WING":
			case "FLOOR":
				return <HouseSiding ref={ref} {...props} />;
			case "ROOM":
				return <MeetingRoom ref={ref} {...props} />;
			case "LOCKER_BANK":
			default:
				return <ListAlt ref={ref} {...props} />;
		}
	},
);
