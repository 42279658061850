import {Card, CardContent, Grid, Link, Typography} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {LockScanStatus} from "./LockScanFormCard";

export default function LockScanInformationCard({
	lockId,
	lockStatus,
}: {
	lockId: string;
	lockStatus: LockScanStatus;
}) {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	return (
		<Card>
			<CardContent>
				<Grid container spacing={1} alignItems="center" mt={1}>
					<LockDetailRow label={t("lockSearch.lockId")} value={lockId} />
					<LockDetailRow label={t("lockSearch.lockInformation")} value={t(`lockSearch.${lockStatus}`)} />

					<Grid item xs={12}>
						{lockStatus === "foundInTenant" && (
							<Link
								color="primary"
								component={RouterLink}
								to={`/${tenantId}/compartments?lockId=${lockId}`}
							>
								{t("lockSearch.goToCompartments")}
							</Link>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

function LockDetailRow({label, value}: { label: string; value: string }) {
	return (
		<>
			<Grid item xs={4}>
				<Typography variant="body1">
					<b>{label}:</b>
				</Typography>
			</Grid>
			<Grid item xs={8}>
				<Typography variant="body1">{value}</Typography>
			</Grid>
		</>
	);
}
