import {SpringPageable} from "@variocube/app-ui";
import {useMemo} from "react";
import {TypeOf} from "zod";
import {useAccessToken} from "../auth";
import {useTenantId} from "../tenant";
import {BookingNameMutation, createApiClient} from "./client";
import {BookingCreation, get_QueryBookings} from "./client.gen";

export function useBookingApi() {
	const accessToken = useAccessToken();
	const tenantId = useTenantId();
	return useMemo(() => createBookingApi(tenantId, accessToken), [tenantId, accessToken]);
}

export type BookingQuery = TypeOf<get_QueryBookings["parameters"]>["query"];

function createBookingApi(tenantId: string, accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		getBooking: (bookingId: number) =>
			api.get("/api/tenants/{tenantId}/bookings/{bookingId}", {
				path: {tenantId, bookingId},
			}),
		updateBookingUntil: (bookingId: number, until: string) =>
			api.put("/api/tenants/{tenantId}/bookings/{bookingId}/until", {
				path: {tenantId, bookingId},
				body: {until},
			}),
		updateBookingName: (bookingId: number, bookingNameMutation: BookingNameMutation) =>
			api.put("/api/tenants/{tenantId}/bookings/{bookingId}/name", {
				path: {tenantId, bookingId},
				body: bookingNameMutation,
			}),
		deleteBooking: (bookingId: number) =>
			api.delete("/api/tenants/{tenantId}/bookings/{bookingId}", {
				path: {tenantId, bookingId},
			}),
		createBooking: (bookingCreation: BookingCreation) =>
			api.post("/api/tenants/{tenantId}/bookings", {
				path: {tenantId},
				body: bookingCreation,
				header: {},
			}),
		queryBookings: (query: BookingQuery) =>
			api.get("/api/tenants/{tenantId}/bookings", {
				path: {tenantId},
				query,
			}),
		getBookingAuditLog: (bookingId: number, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/bookings/{bookingId}/audit", {
				path: {tenantId, bookingId},
				query: pageable,
			}),
		addBookingUser: (bookingId: number, username: string) =>
			api.post("/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}", {
				path: {tenantId, bookingId, username},
			}),
		removeBookingUser: (bookingId: number, username: string) =>
			api.delete("/api/tenants/{tenantId}/bookings/{bookingId}/users/{username}", {
				path: {tenantId, bookingId, username},
			}),
	};
}
