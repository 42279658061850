import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {Button, Card, Chip, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {FeatureEnumValues, usePlanApi} from "../../api/plan";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";

export function PlanView() {
	const {t} = useLocalization();
	const planId = useNumericParam("planId");

	const {getPlan} = usePlanApi();

	const {loading, error, result: plan} = useAsync(getPlan, [planId]);

	return (
		<View loading={loading} error={error}>
			{plan && (
				<>
					<Breadcrumbs>
						<BreadcrumbLink component={Link} to={".."}>{t("plans.title")}</BreadcrumbLink>
						<BreadcrumbItem>{plan?.name}</BreadcrumbItem>
					</Breadcrumbs>

					<ViewHeader
						title={plan.name}
						actions={
							<Button variant="outlined" color="primary" component={Link} to="edit">
								{t("edit")}
							</Button>
						}
					/>

					<Card>
						<List>
							{FeatureEnumValues.map((feature) => (
								<ListItem key={feature}>
									<ListItemIcon>
										{plan.features.includes(feature)
											? <DoneIcon sx={{color: "green"}} />
											: <ClearIcon sx={{color: "red"}} />}
									</ListItemIcon>
									<ListItemText primary={feature} secondary={t(`plans.features.${feature}`)} />
								</ListItem>
							))}
						</List>
					</Card>
				</>
			)}
		</View>
	);
}
