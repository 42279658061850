import {Clear, ExpandLess, ExpandMore} from "@mui/icons-material";
import {ClickAwayListener, IconButton, Popover, TextField} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import {ErrorAlert, useFlag} from "@variocube/app-ui";
import React, {Fragment, useState} from "react";
import {Location, LocationAncestry, LocationTree, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {queryKeys} from "../../utils/queryKeys";
import {LocationTreeView} from "./LocationTreeView";

export interface LocationSelectProps {
	value: LocationTree | Location | LocationAncestry | null;
	onChange: (location: LocationTree | null) => void;
	label?: string;
	fullWidth?: boolean;
	required?: boolean;
	disabledParentId?: number;
}

export function LocationSelect(
	{value, onChange, label, fullWidth, required, disabledParentId}: LocationSelectProps,
) {
	const {t} = useLocalization();
	const tenantId = useTenantId();

	const {getLocationTrees} = useLocationApi();
	const {isLoading, isError, error, data} = useQuery(queryKeys.locationTrees(tenantId), getLocationTrees);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [open, setOpen, clearOpen, toggleOpen] = useFlag(false);

	function handleSelect(location: LocationTree) {
		onChange(location);
		clearOpen();
	}

	function handleClear(event: React.MouseEvent) {
		event.stopPropagation();
		onChange(null);
	}

	return (
		<Fragment>
			{isError && <ErrorAlert error={error} />}
			<TextField
				label={label || t("locations.single")}
				onClick={setOpen}
				value={value?.name ?? ""}
				ref={setAnchorEl}
				fullWidth={fullWidth}
				InputProps={{
					endAdornment: (
						<Fragment>
							{value && (
								<IconButton onClick={handleClear}>
									<Clear />
								</IconButton>
							)}
							<IconButton onClick={toggleOpen}>
								{open ? <ExpandLess /> : <ExpandMore />}
							</IconButton>
						</Fragment>
					),
				}}
				required={required}
			/>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={clearOpen}
				anchorOrigin={{
					horizontal: "left",
					vertical: "bottom",
				}}
				sx={{
					maxHeight: "40vh",
				}}
			>
				<ClickAwayListener onClickAway={clearOpen}>
					<LocationTreeView
						loading={isLoading}
						selected={value ?? undefined}
						locations={data ?? []}
						onSelect={handleSelect}
						disabledParentId={disabledParentId}
					/>
				</ClickAwayListener>
			</Popover>
		</Fragment>
	);
}
