import {DataTable, useDataTableStorage, useSpringPage, useSpringPageable} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {useLocationApi} from "../../api";
import {EmptyList} from "../../controls";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {LocationCell, LocationParentCell, LocationTypeCell} from "./LocationList";

export function LocationChildrenList() {
	const locationId = useNumericParam("locationId");
	const {t} = useLocalization();
	const {getLocationChildren} = useLocationApi();
	const {onPageChange, onSort, ...storage} = useDataTableStorage("LocationChildrenList.paging");
	const pageable = useSpringPageable(storage);
	const {loading, error, result} = useAsync(getLocationChildren, [locationId, pageable]);
	const {rows, page} = useSpringPage(result);

	const columns = useMemo(() => [
		{
			label: t("locations.name"),
			field: "name",
			sortable: true,
			default: true,
			component: LocationCell,
		},
		{
			label: t("locations.type"),
			field: "type",
			sortable: true,
			default: true,
			component: LocationTypeCell,
		},
		{
			label: t("locations.parent"),
			field: "parent.name",
			sortable: true,
			default: true,
			component: LocationParentCell,
		},
	], [t]);

	return (
		<DataTable
			columns={columns}
			rows={rows}
			page={page}
			loading={loading}
			error={error}
			onSort={onSort}
			onPageChange={onPageChange}
			empty={<EmptyList />}
			{...storage}
		/>
	);
}
