import {CardContent, Chip} from "@mui/material";
import {Filter, SpringPageable} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {useGroupApi, UserQuery} from "../../api";
import {useLocalization} from "../../i18n";
import {GroupSelect} from "../group/GroupSelect";

export type UserFilter = Omit<UserQuery, keyof SpringPageable>;

interface UserFilterProps {
	value: UserFilter;
	onChange: (value: UserFilter) => any;
	onSearch: (value?: string) => any;
}

export function UserFilter({value, onChange, onSearch}: UserFilterProps) {
	const {t, s} = useLocalization();
	const {getGroup} = useGroupApi();

	const group = useAsync(async (groupId?: number) => {
		if (groupId) {
			return await getGroup(groupId);
		}
	}, [value.group]);

	return (
		<Filter
			label={t("filter.title")}
			enableSearch
			onSearch={onSearch}
			active={[
				value.search && (
					<Chip
						key={"search"}
						label={`${t("filter.search")}: ${value.search}`}
						onDelete={() => onSearch(undefined)}
					/>
				),
				value.group && (
					<Chip
						key={"group"}
						label={`${t("groups.single")}: ${group.result?.name}`}
						onDelete={() => onChange({...value, group: undefined})}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardContent>
				<GroupSelect
					label={t("groups.single")}
					value={group.result ?? null}
					onChange={group => onChange({...value, group: group?.id})}
				/>
			</CardContent>
		</Filter>
	);
}
