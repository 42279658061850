import {CardContent, Chip} from "@mui/material";
import {Filter, SpringPageable} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {GroupQuery, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationSelect} from "../location/LocationSelect";

export type GroupFilter = Omit<GroupQuery, keyof SpringPageable>;

interface GroupFilterProps {
	value: GroupFilter;
	onChange: (value: GroupFilter) => any;
	onSearch: (value?: string) => any;
}

export function GroupFilter({value, onChange, onSearch}: GroupFilterProps) {
	const {t, s} = useLocalization();

	const {getLocation} = useLocationApi();

	const location = useAsync(async (locationId?: number) => {
		if (locationId) {
			return await getLocation(locationId);
		}
	}, [value.location]);

	return (
		<Filter
			label={t("filter.title")}
			enableSearch
			onSearch={onSearch}
			active={[
				value.search && (
					<Chip
						key={"search"}
						label={`${t("filter.search")}: ${value.search}`}
						onDelete={() => onSearch(undefined)}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardContent>
				<LocationSelect
					value={location.result ?? null}
					onChange={location => onChange({...value, location: location?.id})}
				/>
			</CardContent>
		</Filter>
	);
}
