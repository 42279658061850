import {Box, Grid} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {Booking, BookingNameMutation, useBookingApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {BookingNameEditForm} from "./BookingNameEditForm";

export function BookingEdit() {
	const {t} = useLocalization();
	const bookingId = useNumericParam("bookingId");

	const [booking, setBooking] = useState<Booking>();

	const {getBooking, updateBookingName} = useBookingApi();
	const {loading, error, result} = useAsync(getBooking, [bookingId]);

	useEffect(() => {
		setBooking(result);
	}, [result]);

	async function handleUpdateName(mutation: BookingNameMutation) {
		setBooking(await updateBookingName(bookingId, mutation));
	}

	return (
		<View error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={"../.."} relative="path">{t("bookings.title")}</BreadcrumbLink>
				<BreadcrumbLink component={Link} to={".."} relative="path">{booking?.id}</BreadcrumbLink>
				<BreadcrumbItem>{t("edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("bookings.edit.title", booking)} />
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<BookingNameEditForm loading={loading} booking={booking} onSave={handleUpdateName} />
					</Grid>
				</Grid>
			</Box>
		</View>
	);
}
